import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '@nanostores/react';
import {
  renderToStaticMarkup,
  TReaderDocument,
} from '@usewaypoint/email-builder';
import useGetAppDetails from 'api/app/get-app-details';
import useGetAppSecret from 'api/app/get-app-secret';
import { Channels } from 'api/channels/get-channels/types';
import usePostNotification, {
  PostNotification,
} from 'api/compose/post-notification';
import useGetMe from 'api/profile/get-me';
import useGetUsers from 'api/users/get-users';
import { GetUserResult } from 'api/users/get-users/types';
import { CustomDrawer } from 'components/drawer';
import ErrorBox from 'components/error/ErrorBox';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { buttonVariants } from 'components/shadcn/button';
import { MultiSelect } from 'components/shadcn/multi-select';
import { TextEditor } from 'components/text-editor';
import { useDocument } from 'email-editor/documents/editor/EditorContext';
import { SendHorizontal } from 'lucide-react';
import React from 'react';
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useTemplateStore } from 'store/templateStore';
import useWorkflowStore from 'store/workflowStore';
import { $currentTemplate, $templateConfigValues } from 'templates/store';
import { cn } from 'utils/class-merge';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import { SubmitNotification } from '../../compose/type';
import {
  checkIfOldEmailEditorVersion,
  extractDataFromEditor,
  getDocumentWithLayout,
  getTemplateData,
} from '../functions';
import { useGetEditorType } from '../hooks/getEditorType';
import { useExtractEmailLayouts } from '../hooks/getEmailLayout';
import { useGetFilteredTemplateConfigStates } from '../hooks/getFilteredTemplateConfigStates';

export const TestNotification = ({
  form,
  emailEditorRef,
}: {
  form: UseFormReturn<any, any>;
  emailEditorRef: React.MutableRefObject<any>;
}) => {
  const schema = yup.object({
    recipients: yup
      .array()
      .required('Please select atleast one recipient')
      .min(1, 'Please select atleast one recipient'),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setError,
    clearErrors,
  } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      recipients: [] as GetUserResult[],
      data: `{}`,
    },
  });

  const getMe = useGetMe();
  const { appId } = useAuthStore(state => state);
  const getAppDetails = useGetAppDetails(appId);
  const getAppSecret = useGetAppSecret(appId, true, Infinity);
  const toast = useCustomToast();
  const postNotification = usePostNotification();
  const params = useParams();
  const channel = params.channel as Channels;
  const closeButtonRef = React.useRef(null);
  const [filter, setFilter] = React.useState('');
  const location = useLocation();
  const isLayoutEditorPage = location.pathname.includes('layout-editor');
  const getUsers = useGetUsers({
    pageIndex: 1,
    pageSize: 10,
    filter,
    staleTime: Infinity,
  });
  const { editorTypeTemplate } = useGetEditorType();
  const { workflowJson } = useWorkflowStore(useShallow(state => state));
  const isOldVersion = checkIfOldEmailEditorVersion({
    actingAsTemplateEditor: editorTypeTemplate ? true : false,
    actingAsLayoutEditor: isLayoutEditorPage,
    workflowVersion: workflowJson?._version,
  });
  const document = useDocument();
  const selectedDocumentLayout = useExtractEmailLayouts();
  const filteredTemplateConfigAllStates = useGetFilteredTemplateConfigStates();
  const templateIdentifier = params.templateIdentifier;
  const templateConfigValues = useStore($templateConfigValues);
  const currentTemplate = useStore($currentTemplate);
  const { setLoading } = useTemplateStore(state => state);

  const onSubmit: SubmitHandler<SubmitNotification> = async values => {
    const { data, recipients } = values;
    const formValues = form.getValues();

    const emailData =
      channel === 'email' &&
      (await (async () => {
        const emailEditorData = isOldVersion
          ? await extractDataFromEditor(emailEditorRef)
          : {};

        const baseEmailData = {
          fromName: getMe.data?.clientUser?.email,
          subject: formValues?.subject,
        };

        if (isOldVersion) {
          const oldEmailEditorHtml = (emailEditorData as any)?.html;

          return {
            ...baseEmailData,
            body: oldEmailEditorHtml,
          };
        } else {
          const documentWithLayout = getDocumentWithLayout({
            document,
            documentLayout: selectedDocumentLayout,
          });

          const newEmailEditorHtml = renderToStaticMarkup(
            documentWithLayout as TReaderDocument,
            {
              rootBlockId: 'root',
            },
          );

          return {
            ...baseEmailData,
            body: newEmailEditorHtml,
          };
        }
      })());

    const getTemplateDataResponse =
      channel !== 'email' &&
      (await (async () => {
        return await getTemplateData({
          values: formValues,
          channel,
          tab: 'template',
          filteredTemplateConfigAllStates,
          templateIdentifier,
          templateConfigValues,
          currentTemplate,
          toast,
          setLoading,
        });
      })());

    const overrideData = {
      [channel]:
        channel === 'email'
          ? emailData
          : getTemplateDataResponse?.templateData?.content,
    };

    const submitData: PostNotification = {
      notification: {
        title: 'Notification Test',
      },
      engagespot_api_key: getAppDetails.data.apiKey,
      engagespot_api_secret: getAppSecret.data.apiSecret,
      sendTo: {
        recipients: recipients?.map((recipient: any) => recipient.identifier),
      },
      override: {
        channels: [channel],
        channel: overrideData,
      },
      ...(data && {
        data: JSON.parse(data),
      }),
    };

    postNotification.mutate(submitData, {
      onSuccess: () => {
        closeButtonRef.current.click();
        toast.success('notification send successfully');
      },
      onError: (err: any) => {
        toast.showError(err);
      },
    });
  };

  return (
    <CustomDrawer
      closeButtonRef={closeButtonRef}
      handleClose={() => reset()}
      trigger={() => (
        <button
          className={cn(
            buttonVariants({
              size: 'sm',
              variant: 'outline_hard',
            }),
            'flex gap-2 bg-white text-black',
          )}
        >
          <SendHorizontal className="w-4 h-4" />
          Test
        </button>
      )}
      title={() => <span>Test Notification</span>}
      body={() => {
        return (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-4 flex flex-col gap-3 z-[2002]"
          >
            <div>
              <Controller
                name="recipients"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    data={getUsers.data?.results}
                    setFilter={setFilter}
                    isFetching={getUsers.isFetching}
                    filter={filter}
                    placeholder="Select one or more users to send the notification"
                  ></MultiSelect>
                )}
              />
              {errors.recipients && <ErrorBox error={errors.recipients} />}
            </div>

            <div>
              <Controller
                name="data"
                control={control}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <TextEditor
                      {...field}
                      label="Custom Data"
                      setError={setError}
                      clearErrors={clearErrors}
                      error={error}
                      className="!border-2 !border-[#525151]"
                    />
                  );
                }}
              />
              {errors.data && <ErrorBox error={errors.data} />}
            </div>

            <button
              type="submit"
              disabled={postNotification.isLoading}
              className={cn(buttonVariants({ size: 'lg' }), 'mt-2')}
            >
              Send Test Notification
              {postNotification.isLoading && (
                <LoaderSpinner
                  strokeColor="black"
                  parentClass="relative left-2"
                />
              )}
            </button>
          </form>
        );
      }}
    />
  );
};
