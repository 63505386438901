import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface State {
  email: string;
  password: string;
  clientId: number;
  appId: number;
}

interface Actions {
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setClientId: (clientId: number) => void;
  setAppId: (appId: number) => void;
  reset: () => void;
}

const initialState: State = {
  email: '',
  password: '',
  clientId: 0,
  appId: 0,
};

export const useAuthStore = create<State & Actions>()(
  persist(
    set => ({
      ...initialState,
      setEmail: email => set(() => ({ email })),
      setPassword: password => set(() => ({ password })),
      setClientId: clientId =>
        set(() => {
          return { clientId };
        }),
      setAppId: appId => set(() => ({ appId })),
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'auth-storage',
    },
  ),
);
