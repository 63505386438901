import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetClients } from './types';
import { routes } from 'api/routes';
import React from 'react';
import { useAuthStore } from 'store/authStore';

const get = async (): Promise<GetClients[]> => {
  const { data } = await axiosInstance.get(`${routes['clients']}`);
  return data;
};

const useGetClient = () => {
  // clientId is stored as a zustand value, because it is used in many queryKeys
  // and when it changes causes a refetch in all apis
  const { setClientId, clientId } = useAuthStore(state => state);

  const query = useQuery({
    queryKey: [routes['clients']],
    queryFn: get,
    staleTime: Infinity,
  });

  React.useEffect(() => {
    if (query.data && !clientId) {
      const myClientId = query.data.find(item => item.role === 'owner');
      setClientId(myClientId?.client?.id);
    }
  }, [query.data, setClientId, clientId]);

  return query;
};

export default useGetClient;
