import { yupResolver } from '@hookform/resolvers/yup';
import useGetAppDetails from 'api/app/get-app-details';
import useGetAppSecret from 'api/app/get-app-secret';
import useGetCategories from 'api/categories/get-categories';
import usePostNotification, {
  PostNotification,
} from 'api/compose/post-notification';
import { uploadFilesToServerAndGetUrl } from 'api/files/post';
import useGetAppTemplates from 'api/templates/get-app-templates';
import useGetUsers from 'api/users/get-users';
import { GetUserResult } from 'api/users/get-users/types';
import Card from 'components/card';
import ErrorBox from 'components/error/ErrorBox';
import { FileInputSelect } from 'components/fields/FileInputSelect';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import TextField from 'components/fields/TextField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { MultiSelect } from 'components/shadcn/multi-select';
import { TextEditor } from 'components/text-editor';
import React from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { SubmitNotification } from '../type';
import useGetTopics from 'api/topics/get-topics';
import { useGetWorkflows } from 'api/workflows';
import { DateTimePicker } from 'components/shadcn/date-time-picker';
import { format, formatISO } from 'date-fns';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'components/shadcn/tabs';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';
import PageHeader from 'components/header/PageHeader';

const DashboardComposeSend = () => {
  const [formView, setFormView] = React.useState(1);
  const { appId } = useAuthStore(state => state);
  const toast = useCustomToast();
  const getCategories = useGetCategories();
  const getAppTemplates = useGetAppTemplates();
  const getWorkflows = useGetWorkflows({ published: 'true' });
  const postNotification = usePostNotification();
  const getAppDetails = useGetAppDetails(appId);
  const getAppSecret = useGetAppSecret(appId, true);
  const [filter, setFilter] = React.useState('');
  const getUsers = useGetUsers({ pageIndex: 1, pageSize: 10, filter });
  const getTopics = useGetTopics();

  const schema = yup.object({
    ...(formView === 1 && {
      title: yup.string().required('Notification Title is required'),
    }),
    ...(formView === 2 && {
      templateId: yup.string().required('Please select a template'),
    }),
    ...(formView === 3 && {
      workflowId: yup.string().required('Please select a workflow'),
    }),
    recipients: yup
      .array()
      .required('Please select atleast one recipient')
      .min(1, 'Please select atleast one recipient'),
  });

  const defaultValues = {
    templateId: '',
    category: '',
    title: '',
    message: '',
    icon: '',
    url: '',
    recipients: [] as GetUserResult[],
    data: `{}`,
    recipients_select: 'users' as const,
    cancellationKey: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm<SubmitNotification>({
    reValidateMode: 'onBlur',
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [fileLoading, setFileLoading] = React.useState(false);
  const recipientType = useWatch({
    control,
    name: `recipients_select`,
  });
  const userRecipient = recipientType === 'users';

  const uploadFile = async (file: File) => {
    try {
      const imageFormData = new FormData();
      imageFormData.append('image', file as Blob);
      const uploadResponse = await uploadFilesToServerAndGetUrl(imageFormData);
      return uploadResponse?.data?.url;
    } catch (err) {
      toast.showError(err);
    }
  };

  const recipientSelectOptions = [
    {
      label: 'Users',
      value: 'users',
    },
    {
      label: 'Topics',
      value: 'topics',
    },
  ];

  const categoryOptions = getCategories.data?.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.name,
        value: curr.identifier,
      },
    ],
    [
      {
        label: 'Select Category',
        value: '',
      },
    ],
  );

  const templateOptions = getAppTemplates.data?.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.name,
        value: String(curr.id),
      },
    ],
    [
      {
        label: 'Select Template',
        value: '',
      },
    ],
  );

  const workflowOptions = getWorkflows.data?.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.name,
        value: String(curr.identifier),
      },
    ],
    [
      {
        label: 'Select Workflow',
        value: '',
      },
    ],
  );

  const onSubmit: SubmitHandler<SubmitNotification> = async values => {
    if (values.icon && typeof values.icon !== 'string') {
      setFileLoading(true);

      const file = values.icon[0];
      values.icon = await uploadFile(file);

      setFileLoading(false);
    }

    const { data, recipients, cancellationKey, ...notificationData } = values;

    const submitData: PostNotification = {
      notification: {
        ...notificationData,
        ...(values.workflowId && {
          workflow: {
            identifier: values.workflowId,
            ...(cancellationKey && {
              cancellationKey,
            }),
          },
        }),
      },
      ...(values.sendAt && {
        sendAt: formatISO(values.sendAt),
      }),
      engagespot_api_key: getAppDetails.data.apiKey,
      engagespot_api_secret: getAppSecret.data.apiSecret,
      sendTo: {
        [userRecipient ? 'recipients' : 'topics']: values.recipients.map(
          recipient => recipient.identifier,
        ),
      },
      ...(data && {
        data: JSON.parse(data),
      }),
    };

    postNotification.mutate(submitData, {
      onSuccess: () => {
        toast.success(
          Boolean(values.sendAt)
            ? `Notification will be send at - ${format(values.sendAt, 'd MMMM yyyy, h:mm aaa')}`
            : 'Notification sent successfully. Checks logs to see delivery information',
          { duration: 5000 },
        );
      },
      onError: (err: any) => {
        toast.showError(err);
      },
    });
  };

  return (
    <>
      <PageHeader
        heading={'Compose'}
        text={
          <>
            A tool to test your communication workflows before you integrate
            them.
          </>
        }
      />

      <Card extra={'w-full p-6'}>
        <Tabs defaultValue="1" className="rounded-md">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex  flex-col gap-3 "
          >
            <div className="flex justify-between">
              <h4 className="text-lg font-semibold">Send notification</h4>

              <div className="flex gap-3">
                <button
                  type="button"
                  disabled={postNotification.isLoading || fileLoading}
                  onClick={() => reset(defaultValues)}
                  className={cn(
                    buttonVariants({
                      size: 'sm',
                      className: 'bg-red-20  text-black border border-input',
                    }),
                    'hover:bg-red-20/80',
                  )}
                >
                  Reset
                </button>

                <button
                  type="submit"
                  disabled={postNotification.isLoading || fileLoading}
                  className={cn(
                    buttonVariants({
                      size: 'sm',
                      className: 'font-medium border border-input',
                    }),
                  )}
                >
                  Send Notification
                  {postNotification.isLoading && (
                    <LoaderSpinner
                      strokeColor="black"
                      parentClass="relative left-2"
                    />
                  )}
                </button>
              </div>
            </div>

            <div className="my-4 flex w-full justify-between">
              <div className="relative flex gap-2 rounded-t-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-gray-850 dark:text-white dark:shadow-none">
                <TabsList>
                  <TabsTrigger
                    onClick={() => {
                      reset(defaultValues);
                      setFormView(1);
                    }}
                    value="1"
                  >
                    Simple Notification
                  </TabsTrigger>
                  <TabsTrigger
                    onClick={() => {
                      reset(defaultValues);
                      setFormView(3);
                    }}
                    value="3"
                  >
                    Workflow Notification
                  </TabsTrigger>
                </TabsList>
              </div>
            </div>

            <div className="w-[55%]">
              <div className="relative">
                <Controller
                  name="recipients"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      isMulti={true}
                      data={
                        userRecipient ? getUsers.data?.results : getTopics.data
                      }
                      setFilter={setFilter}
                      isFetching={getUsers.isFetching}
                      filter={filter}
                      placeholder={
                        userRecipient
                          ? 'Select one or more users to send the notification'
                          : 'Select one or more topics to send the notification'
                      }
                      helperText="Please type the identifier to initiate search"
                      label={
                        recipientType === 'users'
                          ? 'Recipients (Users)'
                          : 'Recipients (Topics)'
                      }
                    ></MultiSelect>
                  )}
                />
                {errors.recipients && <ErrorBox error={errors.recipients} />}

                {/* absolute */}
                <div className="absolute top-0 right-0">
                  <SelectField<SubmitNotification>
                    variant="styled"
                    control={control}
                    extra="mb-3"
                    label={''}
                    placeholder={'Notification Category'}
                    name={'recipients_select'}
                    extraInputClass="dark:!bg-night-100 !w-[115px]"
                    options={recipientSelectOptions ?? []}
                    onChange={(value: 'users' | 'topics') => {
                      setValue('recipients', []);
                      setValue(`recipients_select`, value);
                    }}
                  />
                </div>
              </div>

              <TabsContent value="1">
                <>
                  <SelectField<SubmitNotification>
                    variant="styled"
                    control={control}
                    extra="mb-3"
                    label={'Category'}
                    placeholder={'Notification Category'}
                    name={'category'}
                    // extraInputClass="dark:!bg-night-100 !border !border-[#525151]"
                    options={categoryOptions ?? []}
                  />
                  {errors.category && <ErrorBox error={errors.category} />}

                  <FileInputSelect
                    name={'icon'}
                    placeholder={''}
                    required={false}
                    register={register}
                    control={control}
                    setValue={setValue}
                    label={'Icon'}
                    parentClass="my-3"
                  ></FileInputSelect>
                  {errors.icon && <ErrorBox error={errors.icon} />}

                  <InputField<SubmitNotification>
                    label={'Notification title'}
                    placeholder={'Hey, you have a new message!'}
                    type="text"
                    showIsRequiredAsterisk={true}
                    register={register}
                    name={'title'}
                    extraLabelClass={'font-medium'}
                    extraInputClass={
                      'border mb-3 dark:bg-night-100 focus:border border-[#525151]'
                    }
                    required={true}
                  />
                  {errors.title && <ErrorBox error={errors.title} />}

                  <TextField<SubmitNotification>
                    label={'Notification body'}
                    placeholder={'Short and descriptive'}
                    register={register}
                    name={'message'}
                    extraLabelClass={'font-medium'}
                    extraInputClass={
                      'border  dark:bg-night-100 focus:border border-[#525151]'
                    }
                    rows={3}
                  />
                  {errors.message && <ErrorBox error={errors.message} />}

                  <InputField<SubmitNotification>
                    label={'Action url'}
                    placeholder={'https://example.com'}
                    type="text"
                    register={register}
                    name={'url'}
                    extraLabelClass={'font-medium'}
                    extraInputClass={
                      'border mb-2 dark:bg-night-100 focus:border'
                    }
                    extra={'my-3'}
                  />
                  {errors.url && <ErrorBox error={errors.url} />}
                </>
              </TabsContent>

              <TabsContent value="2">
                <>
                  <SelectField
                    variant="styled"
                    control={control}
                    extra="mb-3"
                    label={'Template'}
                    placeholder={'Notification Template'}
                    name={'templateId'}
                    // extraInputClass="dark:!bg-night-100 !border !border-[#525151]"
                    options={templateOptions ?? []}
                  />
                  {errors.templateId && <ErrorBox error={errors.templateId} />}
                </>
              </TabsContent>

              <TabsContent value="3">
                <>
                  <SelectField
                    variant="styled"
                    control={control}
                    extra="mb-3"
                    label={'Workflow'}
                    placeholder={'Select Workflow'}
                    name={'workflowId'}
                    // extraInputClass="dark:!bg-night-100 !border !border-[#525151]"
                    options={workflowOptions ?? []}
                  />
                  {errors.workflowId && <ErrorBox error={errors.workflowId} />}

                  <InputField<SubmitNotification>
                    label={'Cancellation Key'}
                    placeholder={
                      'Enter a key which could be used cancel your workflow run'
                    }
                    type="text"
                    register={register}
                    name={'cancellationKey'}
                    extraLabelClass={'font-medium !ml-1.5'}
                    extraInputClass={
                      'border mb-2 dark:bg-night-100 focus:border'
                    }
                    extra={'my-3'}
                    helperText="Cancellation key is required to cancel your workflow runs. Read <a class='text-decoration-line: underline' target='_blank' href='https://docs.engagespot.com/docs/features/workflows/cancelling'>this guide</a> to learn how to cancel workflows"
                  />
                  {errors.cancellationKey && (
                    <ErrorBox error={errors.cancellationKey} />
                  )}
                </>
              </TabsContent>

              <DateTimePicker label="Send at" control={control} />

              <Controller
                name="data"
                control={control}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <TextEditor<SubmitNotification>
                      {...field}
                      label="Custom data (JSON)"
                      setError={setError}
                      clearErrors={clearErrors}
                      error={error}
                    />
                  );
                }}
              />
              {errors.data && <ErrorBox error={errors.data} />}
            </div>
          </form>
        </Tabs>
      </Card>
    </>
  );
};

export default DashboardComposeSend;
