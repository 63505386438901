import {
  Divider,
  Drawer,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useSamplesDrawerOpen } from '../../documents/editor/EditorContext';
import useGetClientEmailTemplates from 'api/client/get-client-email-templates';
import { useGetEmailLayouts } from 'api/layouts/index';
import { R } from 'utils/remeda-utils';
import SidebarButton from './SidebarButton';
import { useLocation } from 'react-router-dom';

export const SAMPLES_DRAWER_WIDTH = 240;

export default function SamplesDrawer() {
  const location = useLocation();
  const samplesDrawerOpen = useSamplesDrawerOpen();
  const getClientEmailTemplates = useGetClientEmailTemplates();
  const getEmailLayouts = useGetEmailLayouts();
  const isLayoutEditorPage = location.pathname.includes('layout-editor');

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={samplesDrawerOpen}
      sx={{
        width: samplesDrawerOpen ? SAMPLES_DRAWER_WIDTH : 0,
        '& .MuiDrawer-root': {
          position: 'absolute',
        },
        '& .MuiPaper-root': {
          position: 'absolute',
        },
      }}
    >
      <Stack
        spacing={0}
        py={1}
        width={SAMPLES_DRAWER_WIDTH}
        justifyContent="space-between"
        height="100%"
      >
        {!isLayoutEditorPage && (
          <>
            <Stack
              px={2}
              spacing={1}
              sx={{
                '& .MuiButtonBase-root': {
                  width: '100%',
                  justifyContent: 'flex-start',
                },
                height: '50%',
                paddingTop: '.5rem',
              }}
            >
              <Typography variant="h6" component="h1">
                Choose a Layout
              </Typography>

              <Typography variant="caption">
                Choose a layout for this email. Your content will be mixed with
                the choosen layout before email delivery.
                <Link
                  underline="none"
                  rel="noreferrer"
                  target="_blank"
                  href="https://docs.engagespot.co/docs/concepts/layouts"
                  sx={{
                    ml: 0.25,
                  }}
                >
                  Learn more
                </Link>
              </Typography>

              <Stack
                sx={{
                  overflow: 'auto',
                }}
                alignItems="flex-start"
              >
                {getEmailLayouts.isLoading ? (
                  R.times(7, R.identity()).map(i => (
                    <Skeleton
                      sx={{
                        margin: '.3rem 0',
                      }}
                      variant="rectangular"
                      width="100%"
                      height={30}
                    />
                  ))
                ) : (
                  <>
                    {getEmailLayouts.data?.length > 0 &&
                      getEmailLayouts.data.map(layout => {
                        return (
                          <SidebarButton
                            type="layout"
                            href={layout.identifier}
                            key={layout.id}
                            id={layout.identifier}
                          >
                            {layout.name}
                          </SidebarButton>
                        );
                      })}
                  </>
                )}
              </Stack>
            </Stack>

            <Divider />
          </>
        )}
        <Stack
          spacing={1}
          px={2}
          sx={{
            '& .MuiButtonBase-root': {
              width: '100%',
              justifyContent: 'flex-start',
            },
            height: isLayoutEditorPage ? '100%' : '50%',
            marginTop: '0rem',
            paddingTop: isLayoutEditorPage ? '.6rem' : '1rem',
          }}
        >
          <Typography variant="h6" component="h1">
            Template Variables
          </Typography>

          <Typography variant="caption">
            You can make use of <b>{'{{variables}}'}</b> to add dynamic content
            to your email.
            <Link
              underline="none"
              rel="noreferrer"
              target="_blank"
              href="https://docs.engagespot.co/docs/features/workflows/template-editor/overview/#template-variables"
              sx={{
                ml: 0.25,
              }}
            >
              Learn more
            </Link>
          </Typography>

          {/* <Stack
            sx={{
              overflow: 'auto',
            }}
            alignItems="flex-start"
          >
            {getClientEmailTemplates.isLoading ? (
              R.times(7, R.identity()).map(i => (
                <Skeleton
                  sx={{
                    margin: '.3rem 0',
                  }}
                  variant="rectangular"
                  width="100%"
                  height={30}
                />
              ))
            ) : (
              <>
                <SidebarButton id="t#" href="#">
                  Empty
                </SidebarButton>
                {getClientEmailTemplates.data?.length > 0 &&
                  getClientEmailTemplates.data.map(template => {
                    return (
                      <SidebarButton
                        type="template"
                        href={template.jsonUrl}
                        key={template.id}
                        id={template.id}
                      >
                        {template.name}
                      </SidebarButton>
                    );
                  })}
              </>
            )}

            <SidebarButton href="#sample/welcome">Welcome email</SidebarButton>
            <SidebarButton href="#sample/one-time-password">
              One-time passcode (OTP)
            </SidebarButton>
            <SidebarButton href="#sample/reset-password">
              Reset password
            </SidebarButton>
            <SidebarButton href="#sample/order-ecomerce">
              E-commerce receipt
            </SidebarButton>
            <SidebarButton href="#sample/subscription-receipt">
              Subscription receipt
            </SidebarButton>
            <SidebarButton href="#sample/reservation-reminder">
              Reservation reminder
            </SidebarButton>
            <SidebarButton href="#sample/post-metrics-report">
              Post metrics
            </SidebarButton>
            <SidebarButton href="#sample/respond-to-message">
              Respond to inquiry
            </SidebarButton>
          </Stack> */}
        </Stack>
      </Stack>
    </Drawer>
  );
}
