import { yupResolver } from '@hookform/resolvers/yup';
import useGetCategories from 'api/categories/get-categories';
import useGetClientApps from 'api/client/get-client-apps';
import {
  useCreateWorkflow,
  useDuplicateWorkflow,
  useMutateWorkflow,
} from 'api/workflows';
import { AxiosResponse } from 'axios';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import useWorkflowStore from 'store/workflowStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormAddWorkflows } from '../types';

function WorkflowModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { appId, clientId } = useAuthStore(state => state);
  const createWorkflow = useCreateWorkflow();
  const mutateWorkflow = useMutateWorkflow();
  const duplicateWorkflow = useDuplicateWorkflow();
  const getCategories = useGetCategories();
  const getClientApps = useGetClientApps(clientId);
  const toast = useCustomToast();
  const navigate = useNavigate();

  const {
    workflow: selectedWorkflow,
    setWorkflow,
    setWorkflowModalState,
    workflowModalState,
    setSelectedNode,
  } = useWorkflowStore(state => state);

  const defaultValues = React.useMemo(
    () => ({
      category: '',
      name: '',
      appId: '',
      identifier: '',
    }),
    [],
  );

  const schema = yup.object({
    name: yup.string().required('name is required'),
    // category: yup.string().required('category is required'),
    ...(workflowModalState === 'add' && {
      identifier: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          'Identifier can only contain letters, numbers, and underscores',
        )
        .required('identifier is required'),
    }),
    ...(workflowModalState === 'app_copy' && {
      appId: yup
        .string()
        .required('Please select the App you wish to copy this workflow to'),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<FormAddWorkflows>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  React.useEffect(() => {
    if (selectedWorkflow) {
      reset({
        name: selectedWorkflow.name,
        category: String(selectedWorkflow.category?.id),
        ...((workflowModalState === 'duplicate' ||
          workflowModalState === 'app_copy') && {
          identifier: `${selectedWorkflow.identifier}_copy`,
        }),
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, selectedWorkflow, workflowModalState]);

  const handleClose = () => {
    reset(defaultValues);
    setWorkflow(null);
    setWorkflowModalState('add');
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: (
        response: AxiosResponse<{
          id: string;
        }>,
      ) => {
        toast.success(message);

        handleClose();

        const workflowId = response.data.id;

        if (workflowModalState === 'add') {
          setSelectedNode(null);
          navigate(`/admin/workflow-editor/${workflowId}`);
        }
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormAddWorkflows> = values => {
    if (selectedWorkflow) {
      const finalActions = getFinalActions(
        `template ${
          workflowModalState === 'edit'
            ? 'updated'
            : workflowModalState === 'duplicate'
              ? 'duplicated'
              : 'copied to app'
        }  successfully`,
      );

      if (workflowModalState === 'edit') {
        const data = {
          ...values,
          workflowId: selectedWorkflow.id,
          type: 'put' as const,
        };

        mutateWorkflow.mutate(data, finalActions);
      }

      if (workflowModalState === 'duplicate') {
        const { appId, category, ...duplicateValues } = values;

        const data = {
          ...duplicateValues,
          workflowId: selectedWorkflow.id,
          categoryId: Number(values.category),
        };

        duplicateWorkflow.mutate(data, finalActions);
      }

      if (workflowModalState === 'app_copy') {
        const { appId, category, ...duplicateValues } = values;

        const data = {
          ...duplicateValues,
          workflowId: selectedWorkflow.id,
          categoryId: Number(values.category),
          appId: Number(values.appId),
        };

        duplicateWorkflow.mutate(data, finalActions);
      }
    } else {
      // Add template
      const data = {
        ...values,
        appId: String(appId),
      };

      const finalActions = getFinalActions('workflow created successfully');
      createWorkflow.mutate(data, finalActions);
    }
  };

  const categoryOptions = getCategories.data?.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.name,
        value: String(curr.id),
      },
    ],
    [
      {
        label: 'Select Category',
        value: '',
      },
    ],
  );

  const appOptions = getClientApps.data?.reduce(
    (acc, curr: any) => {
      if (curr.id === appId) return acc;

      return [
        ...acc,
        {
          label: curr.name,
          value: String(curr.id),
        },
      ];
    },
    [
      {
        label: 'Select App',
        value: '',
      },
    ],
  );

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold">
        {workflowModalState === 'add'
          ? 'Add'
          : workflowModalState === 'duplicate'
            ? 'Duplicate'
            : workflowModalState === 'app_copy'
              ? 'App Copy'
              : 'Edit'}{' '}
        Workflow
      </h1>

      {/* <p className="mb-6 text-sm text-[#BFBFBF]">
        Read
        <a
          href={
            "https://docs.engagespot.co/docs/features/templates/introduction"
          }
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          docs
        </a>
        to learn more about Templates
      </p> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField<FormAddWorkflows>
          variant="modal"
          extra="mb-3"
          label={'Workflow Name'}
          placeholder={'Enter name'}
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'name'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('name', e.target.value, {
              shouldValidate: true,
            });

            if (
              workflowModalState === 'add' ||
              workflowModalState === 'duplicate'
            ) {
              setValue(
                'identifier',
                e.target.value.toLowerCase().replace(/ /g, '_'),
                {
                  shouldValidate: true,
                },
              );
            }
          }}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        {/* Identifier */}
        {(workflowModalState === 'add' ||
          workflowModalState === 'duplicate' ||
          workflowModalState === 'app_copy' ||
          (workflowModalState === 'edit' && !selectedWorkflow?.identifier)) && (
          <>
            <InputField<FormAddWorkflows>
              variant="modal"
              extra="mb-3"
              label={'Identifier'}
              placeholder="Enter identifier"
              showIsRequiredAsterisk={true}
              type="text"
              register={register}
              name={'identifier'}
            />

            {errors.identifier && <ErrorBox error={errors.identifier} />}
          </>
        )}

        {(workflowModalState === 'add' ||
          workflowModalState === 'edit' ||
          workflowModalState === 'app_copy' ||
          workflowModalState === 'duplicate') && (
          <>
            <SelectField<FormAddWorkflows>
              variant="styled"
              styleVariant="modal"
              extra="mb-3"
              label={'Category'}
              placeholder="Select Category"
              showIsRequiredAsterisk={false}
              control={control}
              name={'category'}
              options={categoryOptions}
            />

            {errors.category && <ErrorBox error={errors.category} />}
          </>
        )}

        {workflowModalState === 'app_copy' && (
          <>
            <SelectField<FormAddWorkflows>
              variant="styled"
              styleVariant="modal"
              extra="mb-3"
              label={'App'}
              placeholder="Select App to copy to"
              showIsRequiredAsterisk={false}
              control={control}
              name={'appId'}
              options={appOptions}
            />

            {errors.appId && <ErrorBox error={errors.appId} />}
          </>
        )}

        <CustomModalButtons
          handleClose={handleClose}
          loading={
            createWorkflow.isLoading ||
            mutateWorkflow.isLoading ||
            duplicateWorkflow.isLoading
          }
        />
      </form>
    </CustomModal>
  );
}

export default WorkflowModal;
