import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { TwoFactorEnableResponse } from '../patch-2fa';

const get = async (): Promise<TwoFactorEnableResponse> => {
  const { data } = await axiosInstance.get(`${routes['2fa']}/recoveryCodes`);

  return data;
};

const useGet2FaRecoveryCodes = (
  onSuccess?: (data: TwoFactorEnableResponse) => void,
) => {
  return useQuery({
    queryKey: [`${routes['2fa']}/recoveryCodes`],
    queryFn: get,
    enabled: false,
    onSuccess,
  });
};

export default useGet2FaRecoveryCodes;
