import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { FormAddUsers } from 'views/admin/dashboards/users/types';
import { GetUserResult } from './get-users/types';

type TPutAddApps = FormAddUsers & {
  appId: number;
};

const put = async (values: TPutAddApps) => {
  const { appId, identifier, email, phoneNumber, ...restOfValues } = values;

  const data = {
    email,
    phoneNumber,
    ...restOfValues,
  };

  return axiosInstance.put<GetUserResult>(
    `${routes['apps']}/${appId}/users/${identifier}`,
    data,
  );
};

const usePutAddUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: put,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/users`]);
    },
  });
};

export default usePutAddUsers;
