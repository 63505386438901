import { EngageSpotChannels } from 'api/channels/get-channels/types';
import { getChannelKeyFromId } from '../../functions';
import { WorkflowConditions } from './types';
import { messageStatusChannelMapping, operatorSymbols } from './variables';

export const getTriggerCondition = (condition: WorkflowConditions[]) => {
  const triggerCondition = (condition as WorkflowConditions[]).reduce(
    (acc, curr) => {
      const {
        condition_type,
        condition_property,
        condition_operator,
        condition_value,
        condition_step_reference,
        condition_step_expression,
        condition_step_join_operator,
        condition_message_status,
        condition_data_key,
      } = curr;

      const isInputStep = condition_step_reference?.includes('waitForInput');
      const isFetchStep = condition_step_reference?.includes('fetch');
      const isDataExpression = condition_step_expression === 'data';
      const isMessageExpression = condition_step_expression === 'message';
      // all engagespot workflow conditions begin with data.
      const valueIsConditionExpression = /^data\./.test(condition_value);

      const finalValue =
        condition_value === 'true' ||
        condition_value === 'false' ||
        valueIsConditionExpression
          ? condition_value
          : `'${condition_value}'`;
      const trimmedConditionProperty = condition_property?.replaceAll(' ', '');
      const expression = `data.${condition_type}.${trimmedConditionProperty} ${condition_operator} ${finalValue}`;
      const stepExpression = `data.${condition_type}.${condition_step_reference}.${condition_step_expression} ${condition_operator} ${finalValue}`;
      const messageStepExpression = `data.${condition_type}.${condition_step_reference}.${condition_step_expression}.${condition_message_status} ${condition_operator} ${finalValue}`;
      const inputStepExpression = `data.${condition_type}.${condition_step_reference}.${condition_step_expression}.input ${condition_operator} ${finalValue}`;
      const fetchStepExpression = `data.${condition_type}.${condition_step_reference}.${condition_step_expression}.${condition_data_key} ${condition_operator} ${finalValue}`;

      const value =
        condition_type === ('step' as string)
          ? isInputStep
            ? inputStepExpression
            : isFetchStep && isDataExpression
              ? fetchStepExpression
              : isMessageExpression
                ? messageStepExpression
                : stepExpression
          : expression;

      const valueWithJoinOperator = Boolean(condition_step_join_operator)
        ? `${value} ${condition_step_join_operator} `
        : value;

      return `${acc}${valueWithJoinOperator}`;
    },
    '',
  );

  return triggerCondition;
};

export const getExpressionValues = (str: string) => {
  const operatorSymbolKeys = Object.keys(operatorSymbols);
  const operators = operatorSymbolKeys.reduce((acc, curr, index) => {
    return `${acc}${(operatorSymbols as any)[curr]}${operatorSymbolKeys.length - 1 === index ? '' : '|'}`;
  }, ``);
  const operatorRegex = new RegExp(`(${operators})`);

  const splitExpressions = str.split(operatorRegex);
  const condition = splitExpressions?.[0]?.trim();
  const operator = splitExpressions?.[1]?.trim();
  const value = splitExpressions?.[2]?.trim();

  const valueWithoutQuote = value?.replaceAll("'", '');
  const conditionSplit = condition?.split('.');
  const conditionType = conditionSplit?.[1];
  const dataProperty = conditionSplit?.[conditionSplit?.length - 1];

  // if recpient or data we have only single expressions

  // Eg: data.recpient.name === vinu
  if (conditionType === 'recipient' || conditionType === 'data') {
    const refill = {
      condition_type: conditionType,
      condition_property: dataProperty,
      condition_operator: operator,
      condition_value: valueWithoutQuote,
    };

    return refill;
  }

  // for steps we have multiple chained expressions

  // Eg: data.step.waitForInput_1.data.success === true
  if (conditionType === 'step') {
    const stepReference = conditionSplit?.[2];
    const stepExpression = conditionSplit?.[3];
    const isMessageStepExpression = stepExpression === 'message';
    const isFetchStep = stepReference?.includes('fetch');
    const messageStatus = conditionSplit?.[4];
    const dataKey = conditionSplit?.[4];

    const refill = {
      condition_type: conditionType,
      condition_step_reference: stepReference,
      condition_step_expression: stepExpression,
      condition_operator: operator,
      condition_value: valueWithoutQuote,
      condition_message_status: isMessageStepExpression ? messageStatus : '',
      condition_data_key: isFetchStep ? dataKey : '',
    };

    return refill;
  }
};

export const getMultipleExpressionValues = (str: string) => {
  if (!Boolean(str)) {
    return [];
  }

  const combinedRegex = /\s*(?:\|\||&&)\s*/;

  const allExpresssions = str.split(combinedRegex);
  const strSplit = str.split(' ');
  const operators = strSplit.filter((item) => item.match(combinedRegex));

  const expresssions = allExpresssions.map((expression, index) => ({
    ...getExpressionValues(expression),
    condition_step_join_operator: operators[index] || '',
  }));

  return expresssions;
};

export const getChannelMessageOptions = (id: string) => {
  const channel = getChannelKeyFromId({ id });
  const messageOptions =
    messageStatusChannelMapping[channel as EngageSpotChannels];
  return messageOptions;
};
