import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetClientUsers } from './types';
import { routes } from 'api/routes';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetClientUsers[]> => {
  const [, clientId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['clients']}/${clientId}/clientUsers`,
  );
  return data;
};

const useGetClientUsers = (clientId: number) => {
  return useQuery({
    queryKey: [`${routes['clients']}/clientUsers`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
  });
};

export default useGetClientUsers;
