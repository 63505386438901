import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetClientDetails } from './types';
import { routes } from 'api/routes';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetClientDetails> => {
  const [, clientId] = queryKey;
  const { data } = await axiosInstance.get(`${routes['clients']}/${clientId}`);
  return data;
};

const useGetClientDetails = (clientId: number) => {
  return useQuery({
    queryKey: [`${routes['clients']}/clientUsers`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
    staleTime: Infinity,
  });
};

export default useGetClientDetails;
