import { Command as CommandPrimitive } from 'cmdk';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { Badge } from 'components/shadcn/badge';
import { Command, CommandGroup, CommandItem } from 'components/shadcn/command';
import * as React from 'react';
import { Noop } from 'react-hook-form';
import { LuX } from 'react-icons/lu';

export function MultiSelect({
  value,
  onChange,
  onBlur,
  setFilter,
  data,
  filter,
  isFetching,
  identifierKey = 'identifier',
  filterKey = 'id',
  isMulti = true,
  placeholder = '',
  helperText,
  dropDownClass = '',
  label = 'Recipients (Users)',
}: {
  value: any[];
  onChange: (...event: any[]) => void;
  onBlur: Noop;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  filter: string;
  data: any;
  isFetching: boolean;
  identifierKey?: string;
  filterKey?: string;
  isMulti?: boolean;
  placeholder?: string;
  helperText?: string;
  dropDownClass?: string;
  label?: string;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [timer, setTimer] = React.useState(null);

  const handleUnselect = React.useCallback(
    (item: any) => {
      const data = value.filter(
        s => s?.[identifierKey] !== item?.[identifierKey],
      );
      onChange(data);
    },
    [identifierKey, onChange, value],
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            const newSelected = [...value];
            newSelected.pop();
            onChange(newSelected);
          }
        }
        // This is not a default behaviour of the <input /> field
        if (e.key === 'Escape') {
          input.blur();
        }
      }
    },
    [onChange, value],
  );

  const selectables = data?.filter(
    (result: any) =>
      !value?.some(item => item?.[filterKey] === result?.[filterKey]),
  );

  return (
    <>
      {label && (
        <label
          className={`text-sm text-navy-700  dark:text-white ${'ml-1.5 font-medium'}`}
        >
          {label}
          {true && <span className="text-brand-400">*</span>}
        </label>
      )}

      {helperText && (
        <p
          className="ml-1 text-sm text-white opacity-[35%]"
          dangerouslySetInnerHTML={{
            __html: helperText,
          }}
        ></p>
      )}

      <Command
        onKeyDown={handleKeyDown}
        className="bg-transparent overflow-visible"
      >
        <div className="group mt-[6px] rounded-md border border-gray-440 bg-night-100 px-3 py-3  text-sm">
          <div className="relative flex flex-wrap gap-1">
            {filter && isFetching && (
              <div className="absolute right-1">
                <LoaderSpinner
                  width="20"
                  strokeColor="gray"
                  parentClass="relative left-2"
                />
              </div>
            )}

            {value?.map(item => {
              return (
                <Badge
                  key={item?.[identifierKey]}
                  variant="secondary"
                  className="!bg-gray-850"
                >
                  {item?.[identifierKey]}
                  <button
                    className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleUnselect(item);
                      }
                    }}
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onClick={() => handleUnselect(item)}
                  >
                    <LuX className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                  </button>
                </Badge>
              );
            })}
            {/* Avoid having the "Search" Icon */}
            <CommandPrimitive.Input
              ref={inputRef}
              value={inputValue}
              onValueChange={value => {
                setInputValue(value);

                if (timer !== null) {
                  clearTimeout(timer);
                }

                let searchTimer = setTimeout(() => {
                  setFilter(value);
                }, 300);

                setTimer(searchTimer);
              }}
              onBlur={() => {
                setOpen(false);
                onBlur();
              }}
              onFocus={() => setOpen(true)}
              placeholder={placeholder}
              className="ml-2 flex-1 bg-night-100 outline-none placeholder:text-muted-foreground"
            />
          </div>
        </div>
        <div className="relative mt-2">
          {open && selectables?.length > 0 ? (
            <div className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
              <CommandGroup className={`h-full overflow-auto ${dropDownClass}`}>
                {selectables?.map((selectedItems: any) => {
                  return (
                    <CommandItem
                      key={selectedItems?.[identifierKey]}
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={() => {
                        setInputValue('');
                        setFilter('');
                        if (isMulti) {
                          onChange([...(value ?? []), selectedItems]);
                        } else {
                          onChange([selectedItems]);
                          inputRef.current.blur();
                        }
                      }}
                      className={'cursor-pointer'}
                    >
                      {selectedItems?.[identifierKey]}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </div>
          ) : null}
        </div>
      </Command>
    </>
  );
}
