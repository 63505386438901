import { EngBox, EngTypography, EngBlueDot, EngImage, EngCard, EngButton, EngInput, EngTextArea, EngRatingStar } from '@engagespot/web-components';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import { jsx } from 'react/jsx-runtime';

/**
  * @engagespot/template-blocks v1.3.7
  *
  * @license undefined
  * This source code is licensed under the undefined license found in the
  * LICENSE file in the root directory of this source tree.
  */
 
var i={box:EngBox,typography:EngTypography,"blue-dot":EngBlueDot,image:EngImage,card:EngCard,button:EngButton,input:EngInput,textarea:EngTextArea,"rating-star":EngRatingStar};setBasePath("https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.8.0/cdn/");var I=({blocks:g,eventListenersToRun:y,changeNotificationState:c,notification:d})=>{let s=g?.reduce((t,n,e)=>{let r=g?.[e-1];r&&r?.type!==n?.type&&(t={...t,[Object.keys(t).length]:[]});let a=Object.keys(t).length-1;return {...t,[a]:[...t?.[a]??[],n]}},{0:[]}),u={button:"row",tags:"row",input:"column",banner_image:"column","rating-star":"column"},b=t=>document.querySelector(`[data-block-id='${t}']`).value;return jsx("div",{className:"engagespot_template_blocks_container",children:Object.keys(s).map(t=>{let n=s[t]?.[0]?.type;return jsx("div",{className:`eng_flex ${u?.[n]==="row"?"eng_flex-row eng_gap-2":"eng_flex-col"}`,children:s[t].map((e,r)=>{let a=i?.[e.type];if(!a)return null;let o=null;y?.length&&c&&d&&(o=y.find(l=>l.blockId===e.id));let p=e.variant==="primary"?"eng_primary-button eng_mt-2":"eng_secondary-button eng_mt-2";(e.type==="textarea"||e.type==="input")&&(p=e.variant==="primary"?"eng_primary-input":"eng_secondary-button"),e.type==="image"&&(p=e.src?"":"eng_hidden"),e.type==="rating-star"&&(p="eng_mb-2");let T={"data-block-id":e.id,size:"small",...e.type==="image"&&{src:typeof e.src=="string"?e.src:e.src?URL.createObjectURL(e.src?.[0]):""},placeholder:e.text,...e.type==="textarea"&&{rows:"2",resize:"auto"},className:p,variant:e.variant,...e.type==="button"&&!!e.link&&{onClick:()=>{window.open(e.link,e.externalLink?"_blank":"_self");}},...o&&{[o.event]:l=>o.onEvent({event:l,changeNotificationState:c,getValue:b,notification:d})}};return jsx(a,{...T,children:e.text},r)})},t)})})};

export { I as TemplateBlocksRender, i as componentMapping };
