import { cn } from 'utils/class-merge';

export const chipBgColors = {
  red: 'text-red-500 bg-red-50',
  green: 'text-green-500 bg-green-50',
};

export const chipTextColors = {
  yellow: '!text-[#C9AE51]',
  green: '!text-[#7DD155]',
  lightGreen: '!text-[#7DD155]',
  popGreen: '!text-[#48FFA7]',
  orange: '!text-[#EA673E]',
  red: '!text-[#A02828]',
  darkGreen: '!text-[#3D8E21]',
  darkYellow: '!text-[#8E8C21]',
};

function Chip({ text, className }: { text: string; className?: string }) {
  return (
    <div
      className={cn(
        `flex items-center justify-center rounded-xl px-2.5 py-1 text-sm font-bold ${chipBgColors['green']}`,
        className,
      )}
    >
      {text}
    </div>
  );
}

export default Chip;
