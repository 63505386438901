import { DEFAULT_WORKFLOW } from 'config';
import Copy from '@assets/svg/common/copy.svg';
import { useDisclosure } from '@chakra-ui/hooks';
import useGetAppDetails from 'api/app/get-app-details';
import Card from 'components/card';
import Chip, { chipBgColors } from 'components/chip';
import EngageSpotInAppComponent from 'components/engagespot';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import Popover from 'components/popover';
import React from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { VscSend } from 'react-icons/vsc';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import atomOneDark from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';
import { useAuthStore } from 'store/authStore';
import { copyToClipboard } from '../functions';
import { TKeyCodeContent, codeContent } from '../variables/codeContent';
import Header from './Header';
import TextContent from './TextContent';
import { cn } from 'utils/class-merge';
import { workflowContents } from '../variables/workflowContents';
import { ENGAGESPOT_BASE_URL } from 'config';
import useGetMe from 'api/profile/get-me';

SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('php', php);

function CodeBlocks({
  initial,
  heading,
  listOfButtons,
  text,
  link,
  extraControlsCss = '',
  extraLinkCss = '',
  extraSyntaxStyle = {},
  apiKey = '',
  apiSecret = '',
  showLineNumbers = true,
  handleSendTestNotification,
  sendNotificationLoading,
  isExploding,
  firstNotificationSend,
  isGetStarted = false,
}: {
  initial: TKeyCodeContent;
  listOfButtons: TKeyCodeContent[];
  heading: string;
  text: string;
  link?: string;
  extraControlsCss?: string;
  extraSyntaxStyle?: Record<string, string>;
  extraLinkCss?: string;
  apiKey?: string;
  apiSecret?: string;
  showLineNumbers?: boolean;
  handleSendTestNotification?: () => void;
  sendNotificationLoading?: boolean;
  isExploding?: boolean;
  firstNotificationSend?: boolean;
  isGetStarted?: boolean;
}) {
  const [selected, setSelected] = React.useState<TKeyCodeContent>(initial);
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { appId } = useAuthStore(state => state);
  const getAppDetails = useGetAppDetails(appId);
  const getMe = useGetMe();

  const customCodeStyle = {
    fontFamily: 'Source Code Pro',
    fontSize: '13px',
    padding: '18px',
    borderRadius: isGetStarted ? '0' : '0 0 20px 20px',
    background: '#111315',
    width: isGetStarted ? '92%' : '65%',
  };

  const handleSelect = (type: TKeyCodeContent) => {
    setSelected(type);
  };

  return (
    <Card extra={'pb-8 px-6 pt-6'}>
      <Header>{heading}</Header>
      <TextContent>
        <p>
          {text}{' '}
          {link && (
            <a
              href={link}
              className={`text-decoration-line: underline text-blue-400 text-sm  ${extraLinkCss}`}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          )}
        </p>
      </TextContent>

      {/* controlls */}
      <div
        style={{ borderRadius: '20px 20px 0 0' }}
        className={cn(
          `mt-4 flex  items-center justify-between  border-b-2 bg-night-100 px-5 py-4 ${extraControlsCss}`,
          isGetStarted ? 'w-[92%]' : 'w-[65%]',
        )}
      >
        <div className="flex gap-2">
          {listOfButtons.map(item => (
            <button
              className={`px-3 py-1 capitalize 3xl:px-5 3xl:py-1 ${
                item === selected ? 'bg-[#313131] opacity-100' : 'opacity-40'
              } rounded-[4px] `}
              key={item}
              onClick={() => handleSelect(item)}
            >
              {item === 'bash' ? 'Curl' : item}
            </button>
          ))}
        </div>

        <div className="flex gap-3">
          <Popover
            trigger={
              <button
                onClick={() => {
                  onToggle();
                  copyToClipboard(
                    codeContent({
                      apiKey,
                      apiSecret,
                      workflowIdentifier: DEFAULT_WORKFLOW,
                      recipient: getMe.data?.clientUser?.email,
                    })[selected],
                  );
                  setTimeout(() => {
                    onClose();
                  }, 1500);
                }}
              >
                <img className="scale-[75%]" src={Copy} alt="copy" />
              </button>
            }
            content={<p className="dark:text-white">copied</p>}
            placement={isGetStarted ? 'left' : 'right'}
            isOpen={isOpen}
            onClose={onClose}
          />

          {isGetStarted && (
            <EngageSpotInAppComponent
              ENGAGESPOT_API_KEY={getAppDetails.data?.apiKey}
              ENGAGESPOT_BASE_URL={ENGAGESPOT_BASE_URL}
              isGetStarted={isGetStarted}
            />
          )}
        </div>
      </div>

      <div className="flex items-center gap-3 ">
        <SyntaxHighlighter
          customStyle={{ ...customCodeStyle, ...extraSyntaxStyle }}
          language={
            selected === 'node' ? 'javascript' : selected ?? 'javascript'
          }
          style={atomOneDark}
          showLineNumbers={showLineNumbers}
        >
          {isGetStarted
            ? workflowContents({
                apiKey,
                apiSecret,
                workflowIdentifier: DEFAULT_WORKFLOW,
                recipient: getMe.data?.clientUser?.email,
              })[selected]
            : codeContent({
                apiKey,
                apiSecret,
                workflowIdentifier: DEFAULT_WORKFLOW,
                recipient: getMe.data?.clientUser?.email,
              })[selected]}
        </SyntaxHighlighter>
      </div>

      {/* Send Message */}
      {isGetStarted && (
        <div
          style={{ borderRadius: '0 0 20px 20px' }}
          className={`flex w-[92%] items-center justify-between border-t-2  border-b-2 border-b-black bg-night-100 px-5 py-4`}
        >
          <div className="flex gap-4">
            <button
              disabled={sendNotificationLoading || firstNotificationSend}
              onClick={handleSendTestNotification}
              className="bg-white text-black flex gap-2 rounded-md font-semibold p-2 px-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <VscSend className="w-6 h-6 bg-white " />
              Trigger Workflow
              {sendNotificationLoading && (
                <LoaderSpinner
                  strokeColor="black"
                  parentClass="relative  left-2"
                />
              )}
              {isExploding && (
                <ConfettiExplosion particleSize={5} duration={3500} />
              )}
            </button>
          </div>

          {firstNotificationSend && (
            <Chip
              text="Workflow has been triggered! You'll receive notifications shortly!"
              className={`py-3 ${chipBgColors['green']} text-sm font-semibold`}
            />
          )}
        </div>
      )}
    </Card>
  );
}

export default CodeBlocks;
