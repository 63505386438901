const EDITION = {
  community: 'community',
  enterprise: 'enterprise',
};

export const getEdition = () => {
  return __EDITION__;
};

export const isEnterpriseEdition = () => {
  return __EDITION__ === EDITION['enterprise'];
};

export const isCommunityEdition = () => {
  return __EDITION__ === EDITION['community'];
};
