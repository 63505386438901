import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { FormChangePassword } from 'views/admin/dashboards/profile/types';

type PutAddApps = FormChangePassword & {
  clientId: number;
};

const put = async (values: PutAddApps) => {
  const { clientId, currentPassword, newPassword } = values;

  const data = {
    currentPassword,
    newPassword,
  };

  return axiosInstance.put(
    `${routes['clientUsers']}/${clientId}/password`,
    data,
  );
};

const usePutPassword = () => {
  return useMutation({
    mutationFn: put,
  });
};

export default usePutPassword;
