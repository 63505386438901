import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { GetMe } from './types';

export const getMe = async (): Promise<GetMe> => {
  const { data } = await axiosInstance.get(`${routes['me']}`);
  return data;
};

const useGetMe = () => {
  return useQuery({
    queryKey: [routes['me']],
    queryFn: getMe,
    staleTime: Infinity,
  });
};

export default useGetMe;
