import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type AddWorkspace = {
  name: string;
};

interface PostAddWorkspaceResponse {
  message: string;
  client: Client;
}

interface Client {
  name: string;
  version: string;
  licenseAuthToken: null;
  status: null;
  id: number;
  createdAt: string;
  updatedAt: string;
  defaultApp: number;
}

const post = async (values: AddWorkspace) => {
  const { name } = values;
  const data = {
    name,
  };

  return axiosInstance.post<PostAddWorkspaceResponse>(
    `${routes['clients']}`,
    data,
  );
};

export const usePostAddWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: post,
    onSuccess: response => {
      queryClient.invalidateQueries([`${routes['clients']}`]);
    },
  });
};
