import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetEmailLayouts } from './types';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { getMilliSecondInMinutes } from 'utils/functions';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetEmailLayouts[]> => {
  const [, clientId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['clients']}/${clientId}/email-layouts`,
  );
  return data;
};

const useGetClientEmailTemplates = (enabled = true) => {
  const { clientId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['clients']}/email-templates`, clientId],
    queryFn: get,
    enabled: Boolean(clientId) && enabled,
    staleTime: getMilliSecondInMinutes(5),
  });
};

export default useGetClientEmailTemplates;
