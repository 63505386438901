import { useGetEmailLayouts } from 'api/layouts';
import { getTemplateDataFromJson } from 'api/templates/get-template-json';
import React from 'react';
import { useLayoutStore } from 'store/layoutStore';
import { R } from 'utils/remeda-utils';

export const useExtractEmailLayouts = () => {
  const [selectedDocumentLayout, setSelectedDocumentLayout] = React.useState();
  const { layoutIdentifier } = useLayoutStore(state => state);
  const getEmailLayouts = useGetEmailLayouts();

  React.useEffect(() => {
    if (getEmailLayouts.isLoading) return;

    const selectedLayout = R.find(
      getEmailLayouts.data ?? [],
      layout => layout?.identifier === layoutIdentifier,
    );

    const data = selectedLayout?.jsonSpec?.bodyJsonTemplate;

    if (data) {
      try {
        getTemplateDataFromJson(data).then(r => {
          setSelectedDocumentLayout(r);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [getEmailLayouts.data, getEmailLayouts.isLoading, layoutIdentifier]);

  return selectedDocumentLayout;
};
