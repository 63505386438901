import { CustomModal, CustomModalPrimaryButton } from 'components/modal';

function VerificationModal({
  isLoading,
  isOpen,
  onClose,
  resendVerificationEmail,
}: {
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  resendVerificationEmail: () => void;
}) {
  return (
    <CustomModal
      modalContentClassName="!min-w-[350px]"
      modalCardClassName="!max-w-[450px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="mb-[20px] text-2xl font-bold">Email Not Verified</h1>
      <p className="mb-[20px]">
        Your email is not verified. Please verify your email.
      </p>
      <div className="flex gap-2">
        <CustomModalPrimaryButton
          loading={isLoading}
          text="Resend Verification Email"
          onClick={resendVerificationEmail}
        />
      </div>
    </CustomModal>
  );
}

export default VerificationModal;
