import useGetAppDetails from 'api/app/get-app-details';
import useGetAppSecret from 'api/app/get-app-secret';
import usePostNotification, {
  PostNotification,
} from 'api/compose/post-notification';
import useGetMe from 'api/profile/get-me';
import Card from 'components/card';
import React from 'react';
import { useAuthStore } from 'store/authStore';
import CodeBlocks from '../apiCredentials/components/CodeBlocks';
import { ExploreCards } from './components/ExploreCards';
import {
  useSetDefaultUser,
  useSetEmailDefaultProvider,
  useSetSmsDefaultProvider,
} from './hooks';
import { exploreSectionData } from './variables';
import { DEFAULT_WORKFLOW } from 'config';

const DashboardGetStarted = () => {
  const [isExploding, setIsExploding] = React.useState(false);
  const postNotification = usePostNotification();
  const { appId } = useAuthStore(state => state);
  const getAppDetails = useGetAppDetails(appId);
  const getAppSecret = useGetAppSecret(appId, true);
  const getMe = useGetMe();
  const [firstNotificationSend, setFirstNotificationSend] =
    React.useState(false);

  const handleSendTestNotification = async () => {
    const submitData: PostNotification = {
      engagespot_api_key: getAppDetails.data.apiKey,
      engagespot_api_secret: getAppSecret.data.apiSecret,
      notification: {
        workflow: {
          identifier: DEFAULT_WORKFLOW,
        },
      },
      sendTo: {
        recipients: [getMe.data?.clientUser?.email],
      },
    };

    postNotification.mutate(submitData, {
      onSuccess: () => {
        setFirstNotificationSend(true);
        setIsExploding(true);

        setTimeout(() => {
          setIsExploding(false);
        }, 1500);
      },
    });
  };

  // useEffect hooks to set default providers and user
  useSetEmailDefaultProvider();
  useSetSmsDefaultProvider();
  useSetDefaultUser();

  return (
    <>
      <div className="mt-5 grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
        {/* body */}
        <div className="col-span-12 mb-5 ">
          <Card extra={'relative border-none'}>
            <div className="col-span-12 flex h-full w-full flex-col gap-[22px] rounded-t-xl 3xl:col-span-4">
              <CodeBlocks
                showLineNumbers={false}
                heading="Trigger your first notification workflow 🚀"
                initial="bash"
                listOfButtons={['node', 'python', 'php', 'ruby', 'bash']}
                text="To help you learn Engagespot, we've added a default_welcome_workflow to your account, and we'll trigger that workflow with the code below!"
                link="https://docs.engagespot.co/docs/features/workflows/sending"
                apiKey={getAppDetails.data?.apiKey}
                apiSecret={getAppSecret.data?.apiSecret}
                handleSendTestNotification={handleSendTestNotification}
                firstNotificationSend={firstNotificationSend}
                sendNotificationLoading={postNotification.isLoading}
                isExploding={isExploding}
                isGetStarted={true}
              />
            </div>

            {/* Explore Section */}
            <div className={`mt-6`}>
              <h3 className=" text-xl font-semibold text-white">Next Steps</h3>

              <p className="mt-2 text-sm font-light text-[#ABB0B6]">
                Engagespot is a powerful infrastructure to build your app's
                multi-channel notification engine. Get started by learning these
                steps.
              </p>

              {/* ExploreCards */}
              <div id="explore" className="mt-4 grid grid-cols-10 gap-3">
                {exploreSectionData.map((item, index) => (
                  <ExploreCards isRecommended={false} {...item} />
                ))}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default DashboardGetStarted;
