import { useDisclosure } from '@chakra-ui/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetAcceptInvitation from 'api/invitation/get-accept-invitation';
import useGetInvitation from 'api/invitation/get-invitation';
import usePostSignUp from 'api/signup/post-signup';
import {
  AuthAgreeToTerms,
  AuthGithubButton,
  AuthHeading,
  AuthORBlock,
  AuthSubmitButton,
} from 'components/auth/AuthComponents';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import LoaderModal from 'components/loader/LoaderModal';
import Cookies from 'js-cookie';
import Default from 'layouts/auth/types/Default';
import React from 'react';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import PhoneInput from 'react-phone-input-2';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { isEnterpriseEdition } from 'utils/get-editions';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { useSetReactPhoneInputCountryWidth } from './hooks';
import { schema } from './schema';
import { IFormSignUp, IPostSignUp } from './types';
import { sources } from './variables';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from 'config';

function SignUpDefault() {
  const setEmail = useAuthStore(state => state.setEmail);
  const setClientId = useAuthStore(state => state.setClientId);
  const [show, setShow] = React.useState(false);
  const togglePasswordView = () => setShow(!show);
  const navigate = useNavigate();
  const postSignUp = usePostSignUp<IPostSignUp>();
  const [searchParams] = useSearchParams();
  const toast = useCustomToast();
  const redirectUrl = new URLSearchParams(searchParams).get('redirect');
  const invitationCode = new URLSearchParams(searchParams).get(
    'invitationCode',
  );
  const redirect = new URLSearchParams(searchParams).get('redirect');
  const userType = new URLSearchParams(searchParams).get('userType');
  const invitedClientId = new URLSearchParams(searchParams).get('workSpaceId');
  const isExistingUser = userType === 'existingUser';
  const loaderModalActions = useDisclosure();
  const invitationFromOrganisation = useGetInvitation(
    invitationCode,
    Boolean(invitationCode) && !isExistingUser,
  );
  const authToken = Cookies.get('access_token');
  const enterpriseEdition = isEnterpriseEdition();
  const sourceOptions = sources?.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr,
        value: curr,
      },
    ],
    [
      {
        label: 'Please select one',
        value: '',
      },
    ],
  );
  const recaptchaRef = React.useRef<ReCAPTCHA>();
  const [recaptchaError, setRecaptchaError] = React.useState(true);

  const getAcceptInvitationOnSuccess = () => {
    toast.success('invitation accepted');

    if (authToken) {
      setClientId(Number(invitedClientId));
      navigate('/admin/settings/teams');
    } else {
      navigate('/auth/login');
    }
  };

  // only existing users can directly accept an invite
  // new users need to signup and will be added to the workspace
  useGetAcceptInvitation(
    invitationCode,
    Boolean(invitationCode) && isExistingUser,
    getAcceptInvitationOnSuccess,
    () => toast.error('Invitation accept failed'),
  );

  const signupSchema = schema.shape({
    ...(!invitationFromOrganisation.data && {
      email: yup
        .string()
        .trim()
        .required('email is required')
        .email('email must be valid'),
    }),
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isSubmitted },
    setValue,
  } = useForm<IFormSignUp>({
    defaultValues: {
      // agree_tos: true,
      marketing_optin: true,
      source: '',
      firstName: '',
      email: '',
      dummy_phone: '',
      dummy_dialCode: '',
    },
    resolver: yupResolver(signupSchema) as Resolver<IFormSignUp>,
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<IFormSignUp> = values => {
    const { dummy_dialCode, dummy_phone, ...necessaryValues } = values;
    const captchaValue = recaptchaRef.current?.getValue();

    if (!captchaValue) {
      return;
    }

    const data = {
      ...necessaryValues,
      agree_tos: 1,
      marketing_optin: values.marketing_optin === true ? 1 : 0,
      phone:
        Boolean(values.phone) && values.phone !== values.dummy_dialCode
          ? `+${values.phone}`
          : '',
      source: values.source || '',
      invitationCode: '',
      hostname: window.location.hostname,
      ...(redirectUrl && {
        redirect: `?redirect=${redirectUrl}`,
      }),
      captchaValue,
    };

    if (invitationFromOrganisation.data) {
      data['email'] = invitationFromOrganisation.data.email;
      data['invitationCode'] = invitationFromOrganisation.data.code;
    } else {
      delete data['invitationCode'];
    }

    postSignUp.mutate(data, {
      onSuccess: response => {
        setEmail(values.email);
        toast.success('Registration Success', {
          duration: 4000,
        });

        if (invitationFromOrganisation.data) {
          const clientId = invitationFromOrganisation.data.invitedTo?.id;
          const token = response.data.token;

          toast.success(
            `Successfully joined ${invitationFromOrganisation.data.invitedTo?.name} workspace`,
            {
              duration: 4000,
            },
          );

          if (clientId && token) {
            setClientId(clientId);

            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              navigate('/admin/get-started');
            }
          }
        } else {
          toast.success(`Please verify email and continue`, {
            duration: 4000,
          });
          navigate(`/auth/verification?redirect=${redirectUrl}`);
        }
      },
    });
  };

  useSetReactPhoneInputCountryWidth();

  return (
    <>
      <LoaderModal
        isOpen={isExistingUser}
        onClose={loaderModalActions.onClose}
      />

      <Default
        maincard={
          <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
            {/* Sign up section */}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-[10vh] min-h-[948px] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]"
            >
              <AuthHeading text="Create an Engagespot account" />

              <div className="mb-7 mt-2 text-lg">
                <span className="font-normal  text-gray-10">
                  {invitationFromOrganisation.data ? (
                    <>
                      Enter your details to join
                      <span className="ml-2 text-yellow-450">
                        {invitationFromOrganisation.data?.invitedTo?.name}
                      </span>
                    </>
                  ) : (
                    'Already have an account?'
                  )}
                </span>

                <Link
                  to={
                    redirect
                      ? `/auth/login?redirect=${redirect}`
                      : '/auth/login'
                  }
                  className="hover:text-yellow-60 ml-1 font-normal text-yellow-450 hover:text-yellow-600"
                >
                  {'Login'}
                </Link>
              </div>

              {/* Name */}
              <div className="mb-7">
                <InputField<IFormSignUp>
                  variant="dark"
                  extra=""
                  label="Name"
                  placeholder="Enter name"
                  id="name"
                  type="text"
                  showIsRequiredAsterisk={true}
                  register={register}
                  name={'firstName'}
                />
                {errors.firstName && <ErrorBox error={errors.firstName} />}
              </div>

              {/* Email */}
              {!invitationFromOrganisation.data && (
                <div className="mb-7">
                  <InputField
                    variant="dark"
                    extra=""
                    label="Email"
                    placeholder="Enter email"
                    id="email"
                    type="email"
                    showIsRequiredAsterisk={true}
                    register={register}
                    name={'email'}
                  />
                  {errors.email && <ErrorBox error={errors.email} />}
                </div>
              )}

              {/* Password */}
              <div className="mb-7">
                <div className="relative">
                  <InputField
                    variant="dark"
                    extra=""
                    label="Password"
                    placeholder="Enter password"
                    id="password"
                    type={show ? 'text' : 'password'}
                    showIsRequiredAsterisk={true}
                    register={register}
                    name={'password'}
                  />

                  <button
                    type="button"
                    onClick={togglePasswordView}
                    className="absolute right-4 top-[55%] text-white"
                  >
                    {show ? (
                      <RiEyeCloseLine size={22} />
                    ) : (
                      <MdOutlineRemoveRedEye size={22} />
                    )}
                  </button>
                </div>
                {errors.password && <ErrorBox error={errors.password} />}
              </div>

              {/* PhoneNumber */}
              <div id="phone__input__container" className="relative mb-7">
                <Controller
                  name={'phone'}
                  control={control}
                  render={({ field }) => (
                    <>
                      <label
                        className={`ml-1.5  !text-base font-normal dark:!text-gray-10`}
                      >
                        Phone number
                      </label>

                      <div className="mt-2">
                        <PhoneInput
                          {...field}
                          onChange={(value, data) => {
                            field.onChange(value);
                            setValue(
                              'dummy_dialCode',
                              (data as any)?.dialCode ?? '',
                            );
                            setValue('dummy_phone', value);
                          }}
                          country="us"
                          placeholder=""
                        />
                      </div>
                    </>
                  )}
                />

                {/* we cannot directly pass ref to react phone input */}
                {/* there are validation issues, to combat this dummy phone input */}
                <input type="hidden" {...register('dummy_phone')} />
                <input type="hidden" {...register('dummy_dialCode')} />

                {errors.dummy_phone && <ErrorBox error={errors.dummy_phone} />}
              </div>

              {/* source */}
              {!invitationFromOrganisation.data && (
                <div className="mb-7 mt-3">
                  <SelectField
                    variant="styled"
                    extra=""
                    label={'How did you hear about us?'}
                    placeholder=""
                    showIsRequiredAsterisk={false}
                    control={control}
                    name={'source'}
                    extraInputClass="border border-gray-440 !h-[48px] !rounded-md !bg-night-400 text-white !text-base  outline-none"
                    extraItemClass={'!text-base'}
                    extraLabelClass="ml-1.5  !text-base font-normal dark:!text-gray-10"
                    options={sourceOptions}
                  />
                </div>
              )}

              {/* RECAPTCHA */}
              <div>
                <div className=" bg-[#222222] w-full rounded-md px-2 py-0">
                  <div className="g-recaptcha-wrapper">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                      theme="dark"
                      onChange={value => {
                        setRecaptchaError(false);
                      }}
                    />
                  </div>
                </div>
                {isSubmitted && recaptchaError && (
                  <ErrorBox error={'reCAPTCHA verification is required'} />
                )}
              </div>

              {/* Checkbox */}
              {/* <div className="mt-4 flex items-center justify-between px-2">
                <div className="flex">
                  <Controller
                    name="agree_tos"
                    control={control}
                    render={({ field }) => {
                      return (
                        <>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            id="checkbox"
                          />
                          <label
                            htmlFor="checkbox"
                            className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                          >
                            By creating an account means you agree to the Terms
                            and Conditions, and our Privacy Policy
                          </label>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              {errors.agree_tos && <ErrorBox error={errors.agree_tos} />} */}

              {/* button */}
              {postSignUp.error && <ErrorBox error={postSignUp.error} />}

              <AuthSubmitButton
                isLoading={postSignUp.isLoading}
                disabled={postSignUp.isLoading}
                text="Create account"
              />

              {enterpriseEdition && (
                <>
                  <AuthORBlock />
                  <AuthGithubButton />
                </>
              )}

              <AuthAgreeToTerms text="By signing up ,you agree to our" />
            </form>
          </div>
        }
      />
    </>
  );
}

export default SignUpDefault;
