import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import {
  DeleteLayout,
  GetLayouts,
  PatchUpdateLayoutJson,
  PostCreateLayout,
  PutEditLayout,
  LayoutDetails,
  PostSaveEmailLayout,
} from './types';

const getLayouts = async ({
  queryKey,
}: QueryFunctionContext<[string, number, boolean]>): Promise<GetLayouts> => {
  const [, appId, published] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/layouts`,
    {
      params: published
        ? {
            published,
          }
        : undefined,
    },
  );

  return data;
};

const useGetEmailLayouts = (published: boolean = false) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/layouts`, appId, published],
    queryFn: getLayouts,
  });
};

const getLayoutDetails = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<LayoutDetails> => {
  const [, layoutId] = queryKey;
  const { data } = await axiosInstance.get(`${routes['layouts']}/${layoutId}`);
  return data;
};

const useGetLayoutDetails = (layoutId: number, enabled = true) => {
  return useQuery({
    queryKey: [`${routes['layouts']}/details`, layoutId],
    queryFn: getLayoutDetails,
    enabled: Boolean(layoutId) && enabled,
  });
};

const patchLayoutJson = async (values: PatchUpdateLayoutJson) => {
  const { layoutId, ...data } = values;
  return axiosInstance.patch(`${routes['layouts']}/${layoutId}`, data);
};

const usePatchUpdateLayoutJson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchLayoutJson,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['layouts']}/details`]);
    },
  });
};

const setDefaultLayout = async (values: DeleteLayout) => {
  const { layoutId } = values;
  return axiosInstance.put(`${routes['layouts']}/${layoutId}/default`);
};

const useSetDefaultLayout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setDefaultLayout,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/layouts`]);
    },
  });
};

const getDefaultLayout = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetLayouts[0]> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/layouts/default`,
  );
  return data;
};

const useGetDefaultLayout = () => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['layouts']}/default`, appId],
    queryFn: getDefaultLayout,
  });
};

const deleteLayout = async (values: DeleteLayout) => {
  const { layoutId } = values;
  return axiosInstance.delete(`${routes['layouts']}/${layoutId}`);
};

const useDeleteLayout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteLayout,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/layouts`]);
    },
  });
};

const postCreateLayout = async (values: PostCreateLayout) => {
  const { appId, ...data } = values;
  return axiosInstance.post(`${routes['apps']}/${appId}/layouts`, data);
};

const usePostCreateLayout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postCreateLayout,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/layouts`]);
    },
  });
};

const postSaveEmailLayout = async (values: PostSaveEmailLayout) => {
  const { layoutId, ...data } = values;
  return axiosInstance.post(`${routes['layouts']}/${layoutId}/content`, data);
};

const usePostSaveEmailLayout = () => {
  return useMutation({
    mutationFn: postSaveEmailLayout,
  });
};

const putEditLayout = async (values: PutEditLayout) => {
  const { layoutId, ...data } = values;
  return axiosInstance.put(`${routes['layouts']}/${layoutId}`, data);
};

const usePutEditLayout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putEditLayout,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/layouts`]);
    },
  });
};

export {
  useGetEmailLayouts,
  useGetLayoutDetails,
  usePatchUpdateLayoutJson,
  useSetDefaultLayout,
  useGetDefaultLayout,
  useDeleteLayout,
  usePostCreateLayout,
  usePutEditLayout,
  usePostSaveEmailLayout,
};
