import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

const post = async <T>(values: T & { redirect?: string }) => {
  const { redirect = '', ...data } = values;

  return axiosInstance.post(`${routes['forgotPassword']}${redirect}`, data);
};

const usePostForgotPassword = <T>() => {
  return useMutation({
    mutationFn: post<T>,
  });
};

export default usePostForgotPassword;
