import { yupResolver } from '@hookform/resolvers/yup';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import React from 'react';
import {
  UseFieldArrayReturn,
  UseFormReturn,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { MdDelete, MdModeEditOutline } from 'react-icons/md';
import useWorkflowStore from 'store/workflowStore';
import { cn } from 'utils/class-merge';
import { useShallow } from 'zustand/react/shallow';
import {
  findSelectedNodeFromJson,
  findStepsBeforeSelectedNodeFromJson,
} from '../../functions';
import { workflowDocLinks } from '../../variables';
import { LearnMore } from '../common';
import {
  getChannelMessageOptions,
  getMultipleExpressionValues,
  getTriggerCondition,
} from './functions';
import { workflowConditionSchema } from './schema';
import { FormWorkflowConditions, WorkflowConditions } from './types';
import {
  conditionTypeOptions,
  defaultConditionExpressions,
  expressionStateDataOptions,
  expressionStateMessageOptions,
  expressionStateOptions,
  operatorOptions,
  operatorsDescription,
  stateOptions,
  trueOrFalseOptions,
  truthyDescription,
  truthyOperatorOptions,
} from './variables';

const WorkflowCondition = ({
  triggerCondition,
  index,
  formMethods,
  fieldArrayMethods,
  branchIndex,
}: {
  branchIndex?: string;
  triggerCondition?: string;
  index: number;
  formMethods: UseFormReturn<FormWorkflowConditions, any>;
  fieldArrayMethods: UseFieldArrayReturn<
    FormWorkflowConditions,
    'condition',
    'id'
  >;
}) => {
  const globalFormMethods = useFormContext();
  const { append, remove, fields, insert } = fieldArrayMethods;
  const [showForm, setShowForm] = React.useState(false);
  const [showDetailCard, setShowDetailCard] = React.useState(false);
  const {
    register,
    formState: { errors, isValid },
    control,
    setValue,
    getValues,
    trigger,
  } = formMethods;

  const [selectedCondition, setSelectedCondition] = React.useState(null);

  const {
    condition_step_reference,
    condition_step_expression,
    condition_type,
    condition_property,
    condition_operator,
    condition_value,
    condition_step_join_operator,
    condition_message_status,
    condition_data_key,
  } = selectedCondition || {};

  const branchConditionName = `branchCondition.${branchIndex}.triggerCondition`;
  const expressionValue = `${condition_type}.${condition_property}`;
  // this expression is only available in chanel step so need to check again
  const isMessageExpression = condition_step_expression === 'message';
  const isDataExpression = condition_step_expression === 'data';
  const isFetchStep = condition_step_reference?.includes('fetch');

  const stepExpressionValue =
    selectedCondition && condition_type === 'step'
      ? isMessageExpression
        ? `${condition_type}.${condition_step_reference}.${condition_step_expression}.${condition_message_status}`
        : isFetchStep && isDataExpression
          ? `${condition_type}.${condition_step_reference}.${condition_step_expression}.${condition_data_key}`
          : `${condition_type}.${condition_step_reference}.${condition_step_expression}`
      : '';

  const { workflowJson, selectedNode } = useWorkflowStore(
    useShallow(state => state),
  );

  const stepsBeforeSelectedNodeFromJson = findStepsBeforeSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId: selectedNode?.id,
  });

  const stepOptions = stepsBeforeSelectedNodeFromJson.map(channel => ({
    label: channel.ref,
    value: channel.ref,
  }));

  const conditionType = useWatch({
    control,
    name: `condition.${index}.condition_type`,
  });

  const stepExpression = useWatch({
    control,
    name: `condition.${index}.condition_step_expression`,
  });

  const stepReference = useWatch({
    control,
    name: `condition.${index}.condition_step_reference`,
  });

  const checkIfSelectedStepIsChannel = () => {
    const selectedStep = stepsBeforeSelectedNodeFromJson.find(
      json => json.ref === stepReference,
    );

    return Boolean(selectedStep?.channelKey);
  };

  const checkIfSelectedStepIsGettingInput = () => {
    const gettinInputValues = ['waitForInput', 'fetch'];
    const valueType = stepReference?.split('_')?.[0];
    return gettinInputValues.includes(valueType);
  };

  const checkIfGivenStepIsChannel = (ref: string) => {
    const selectedStep = stepsBeforeSelectedNodeFromJson.find(
      json => json.ref === ref,
    );

    return Boolean(selectedStep?.channelKey);
  };

  const onSubmit = async () => {
    const noErrors = await trigger();

    if (noErrors === false) return;

    const values = getValues();
    const { condition } = values;
    const triggerCondition = getTriggerCondition(condition);

    globalFormMethods.setValue(
      branchIndex ? branchConditionName : 'triggerCondition',
      triggerCondition,
      {
        shouldValidate: branchIndex ? true : false,
      },
    );

    setShowForm(false);
    setShowDetailCard(true);
  };

  // UseEffects
  React.useEffect(() => {
    if (selectedCondition !== null) return;
    const { condition } = getValues();
    setSelectedCondition(condition?.[index]);
  }, [getValues, index, selectedCondition]);

  React.useEffect(() => {
    if (
      Boolean(triggerCondition) &&
      // to catch the default value case, on delete
      Boolean(selectedCondition?.condition_value)
    ) {
      setShowDetailCard(true);
    }
  }, [triggerCondition, selectedCondition]);

  React.useEffect(() => {
    if (selectedCondition?.showForm) {
      setShowForm(true);
    }
  }, [selectedCondition]);

  const handleDelete = () => {
    const isLastItem = fields?.length - 1 === index;

    // if the deleted condition has || join operator,
    // attach it to the previous condition
    if (condition_step_join_operator && condition_step_join_operator === '||') {
      setValue(
        `condition.${index - 1}.condition_step_join_operator`,
        condition_step_join_operator,
      );
    }

    // since the last item is deleted,
    // no need for any join operators in second last item
    if (isLastItem) {
      setValue(`condition.${index - 1}.condition_step_join_operator`, '');
    }

    remove(index);
    const values = getValues();
    const { condition } = values;

    const triggerCondition = getTriggerCondition(condition);
    globalFormMethods.setValue(
      branchIndex ? branchConditionName : 'triggerCondition',
      triggerCondition,
      {
        shouldValidate: branchIndex ? true : false,
      },
    );

    if (fields.length === 1 && index === 0) {
      setTimeout(() => {
        append(defaultConditionExpressions);
      }, 0);
    }
  };

  const handleCancel = () => {
    setShowForm(false);

    const values = getValues();
    const { condition } = values;
    const conditionExpressions = getMultipleExpressionValues(triggerCondition);

    if (condition.length !== conditionExpressions.length) {
      handleDelete();
    }

    if (Boolean(triggerCondition)) {
      setShowDetailCard(true);
    }
  };

  const sideLineCss = `before:absolute before:bg-gray-930 before:w-[1px] before:h-full before:-top-1 before:-left-3`;
  return (
    <div className="w-[96%] mx-auto">
      <div className="flex flex-col gap-3">
        {!showForm && !showDetailCard && (
          <button
            onClick={() => setShowForm(true)}
            className="bg-white w-fit p-2 mt-1 rounded-md text-black px-4"
            type="button"
          >
            Add condition
          </button>
        )}
      </div>

      {/* card */}
      <div
        className={cn(
          'w-[95%] mx-auto relative',
          sideLineCss,
          condition_step_join_operator === '' ? 'before:h-[70%]' : '',
          condition_step_join_operator === '&&' ? 'before:h-[145%]' : '',
          condition_step_join_operator === '||' ? 'before:h-[85%]' : '',
          condition_step_join_operator === '' && showForm
            ? 'before:h-[100%]'
            : '',
          condition_step_join_operator === '||' && showForm
            ? 'before:h-[95%]'
            : '',
          condition_step_join_operator === '' &&
            showForm &&
            fieldArrayMethods.fields.length === 1
            ? 'before:h-[0%] w-full'
            : '',
        )}
      >
        {showDetailCard && (
          <div>
            <div className="bg-[#f7f7ff] text-black text-sm rounded mt-4 p-2 flex justify-between">
              <div className="flex w-[80%] gap-2 flex-wrap">
                <div className="flex gap-2 overflow-hidden  whitespace-nowrap text-ellipsis">
                  <span className="text-[#e54d2e] overflow-hidden  whitespace-nowrap text-ellipsis">
                    {`${condition_type === 'step' ? stepExpressionValue : expressionValue}`}
                  </span>

                  <span className="overflow-hidden  whitespace-nowrap text-ellipsis">
                    {(operatorsDescription as any)[condition_operator]}
                  </span>
                </div>

                <span className="text-[#e54d2e] overflow-hidden whitespace-nowrap text-ellipsis">
                  {condition_value}
                </span>
              </div>

              <div className="flex gap-2 text-lg w-[20%] relative left-4">
                <button
                  type="button"
                  onClick={() => {
                    setShowDetailCard(false);
                    setShowForm(true);
                  }}
                >
                  <MdModeEditOutline />
                </button>

                <button
                  type="button"
                  onClick={handleDelete}
                  className="text-red-400"
                >
                  <MdDelete />
                </button>
              </div>
            </div>

            {!showForm &&
              selectedCondition?.condition_step_join_operator !== '&&' && (
                <div className={'flex flex-col gap-4 mt-4 text-black'}>
                  <button
                    onClick={() => {
                      const values = getValues();
                      const { condition } = values;
                      const previousExpression = condition?.[index];

                      insert(index + 1, {
                        ...defaultConditionExpressions,
                        showForm: true,
                        condition_step_join_operator:
                          previousExpression?.condition_step_join_operator ||
                          '',
                      });

                      setValue(
                        `condition.${index}.condition_step_join_operator`,
                        '&&',
                      );
                    }}
                    type="button"
                    className="bg-white w-fit py-1 px-2 rounded-md"
                  >
                    + and
                  </button>

                  {index === fields.length - 1 && (
                    <button
                      onClick={() => {
                        setValue(
                          `condition.${index}.condition_step_join_operator`,
                          '||',
                        );

                        append({
                          ...defaultConditionExpressions,
                          showForm: true,
                        });
                      }}
                      type="button"
                      className="bg-white py-1 px-2 rounded-md w-fit relative right-2"
                    >
                      + or
                    </button>
                  )}
                </div>
              )}
          </div>
        )}

        {showForm && (
          <div
            className={cn(
              'flex flex-col gap-4 mt-4 relative border border-gray-930 p-3 py-5 rounded-md',
            )}
          >
            <div>
              <SelectField
                variant="styled"
                control={control}
                extra="w-full"
                label={'Condition Type'}
                placeholder={'Select condition type'}
                extraInputClass="capitalize"
                name={`condition.${index}.condition_type`}
                styleVariant="workflow"
                extraItemClass="capitalize"
                options={conditionTypeOptions ?? []}
              />
              {errors?.condition?.[index]?.condition_type && (
                <ErrorBox error={errors?.condition?.[index]?.condition_type} />
              )}
            </div>

            {conditionType === 'step' && (
              <>
                <div>
                  <SelectField
                    variant="styled"
                    control={control}
                    extra="w-full"
                    label={'Previous step'}
                    placeholder={'Choose a step'}
                    extraInputClass=""
                    name={`condition.${index}.condition_step_reference`}
                    styleVariant="workflow"
                    extraItemClass=""
                    options={stepOptions ?? []}
                    onChange={(value: string) => {
                      const wasPreviousValueChannel =
                        checkIfSelectedStepIsChannel();

                      const wasPreviousValueGettingInput =
                        checkIfSelectedStepIsGettingInput();

                      const currentValueIsChannel =
                        checkIfGivenStepIsChannel(value);

                      setValue(
                        `condition.${index}.condition_step_reference`,
                        value,
                      );

                      // only for channel stepRef, message expressions option is shown
                      // so if changing from channel to not channel, reset accordingly
                      if (
                        wasPreviousValueChannel &&
                        !currentValueIsChannel &&
                        stepExpression === 'message'
                      ) {
                        setValue(
                          `condition.${index}.condition_step_expression`,
                          '',
                        );
                        setValue(`condition.${index}.condition_value`, '', {
                          shouldValidate: true,
                        });
                      }

                      // stepExpression data is only avaiable for steps which
                      // collect input - waitForinput and fetch
                      if (
                        wasPreviousValueGettingInput &&
                        stepExpression === 'data'
                      ) {
                        setValue(
                          `condition.${index}.condition_step_expression`,
                          '',
                          {
                            shouldValidate: true,
                          },
                        );
                      }
                      // The message status changes based on the step reference type
                      setValue(
                        `condition.${index}.condition_message_status`,
                        '',
                        {
                          shouldValidate: true,
                        },
                      );
                    }}
                  />
                  {errors?.condition?.[index]?.condition_step_reference && (
                    <ErrorBox
                      error={
                        errors?.condition?.[index]?.condition_step_reference
                      }
                    />
                  )}
                </div>

                <div>
                  <SelectField
                    variant="styled"
                    control={control}
                    extra="w-full"
                    label={'Data Property'}
                    placeholder={'Data Property'}
                    extraInputClass=""
                    name={`condition.${index}.condition_step_expression`}
                    styleVariant="workflow"
                    extraItemClass=""
                    options={
                      checkIfSelectedStepIsChannel()
                        ? expressionStateMessageOptions
                        : checkIfSelectedStepIsGettingInput()
                          ? expressionStateDataOptions
                          : expressionStateOptions
                    }
                    onChange={(value: string) => {
                      setValue(
                        `condition.${index}.condition_step_expression`,
                        value,
                      );
                      setValue(`condition.${index}.condition_value`, '');

                      if (value === 'message') {
                        setValue(`condition.${index}.condition_operator`, '');
                      }

                      setValue(
                        `condition.${index}.condition_message_status`,
                        '',
                        { shouldValidate: true },
                      );
                    }}
                  />
                  {errors?.condition?.[index]?.condition_step_expression && (
                    <ErrorBox
                      error={
                        errors?.condition?.[index]?.condition_step_expression
                      }
                    />
                  )}
                </div>

                {stepReference?.includes('fetch') &&
                  stepExpression === 'data' && (
                    <div>
                      <InputField
                        extraInputClass={
                          'border dark:bg-night-100 focus:border border-[#525151]'
                        }
                        label={'Data Key'}
                        placeholder={'Enter Data Key'}
                        register={register}
                        name={`condition.${index}.condition_data_key`}
                        extraLabelClass={
                          'font-medium !text-sm dark:!text-white'
                        }
                        variant="workflow"
                      />
                      {errors?.condition?.[index]?.condition_data_key && (
                        <ErrorBox
                          error={errors?.condition?.[index]?.condition_data_key}
                        />
                      )}
                    </div>
                  )}

                {stepExpression === 'message' &&
                  checkIfSelectedStepIsChannel() && (
                    <div>
                      <SelectField
                        variant="styled"
                        control={control}
                        extra="w-full"
                        label={'Message Status'}
                        placeholder={'Message Status'}
                        extraInputClass=""
                        name={`condition.${index}.condition_message_status`}
                        styleVariant="workflow"
                        extraItemClass=""
                        options={getChannelMessageOptions(stepReference)}
                        onChange={(value: string) => {
                          setValue(
                            `condition.${index}.condition_message_status`,
                            value,
                            {
                              shouldValidate: true,
                            },
                          );
                        }}
                      />
                      {errors?.condition?.[index]
                        ?.condition_step_expression && (
                        <ErrorBox
                          error={
                            errors?.condition?.[index]
                              ?.condition_step_expression
                          }
                        />
                      )}
                    </div>
                  )}
              </>
            )}

            {conditionType !== 'step' && (
              <div>
                <InputField
                  extraInputClass={
                    'border dark:bg-night-100 focus:border border-[#525151]'
                  }
                  label={'Data Property'}
                  placeholder={'Enter Property'}
                  register={register}
                  name={`condition.${index}.condition_property`}
                  extraLabelClass={'font-medium !text-sm dark:!text-white'}
                  variant="workflow"
                />
                {errors?.condition?.[index]?.condition_property && (
                  <ErrorBox
                    error={errors?.condition?.[index]?.condition_property}
                  />
                )}
              </div>
            )}

            <div>
              <SelectField
                variant="styled"
                control={control}
                extra="w-full"
                label={'Operator'}
                placeholder={'Select operator'}
                extraInputClass=""
                name={`condition.${index}.condition_operator`}
                styleVariant="workflow"
                extraItemClass=""
                options={
                  stepExpression === 'message' && checkIfSelectedStepIsChannel()
                    ? truthyOperatorOptions
                    : operatorOptions
                }
              />
              {errors?.condition?.[index]?.condition_operator && (
                <ErrorBox
                  error={errors?.condition?.[index]?.condition_operator}
                />
              )}
            </div>

            <div>
              {conditionType === 'step' ? (
                <>
                  {stepExpression === 'state' ? (
                    <SelectField
                      variant="styled"
                      control={control}
                      extra="w-full"
                      label={'Data Property Value'}
                      placeholder={'Select property value'}
                      extraInputClass=""
                      name={`condition.${index}.condition_value`}
                      styleVariant="workflow"
                      extraItemClass=""
                      options={stateOptions ?? []}
                    />
                  ) : stepExpression === 'message' &&
                    checkIfSelectedStepIsChannel() ? (
                    <SelectField
                      variant="styled"
                      control={control}
                      extra="w-full"
                      label={'Data Property Value'}
                      placeholder={'Select property value'}
                      extraInputClass=""
                      name={`condition.${index}.condition_value`}
                      styleVariant="workflow"
                      extraItemClass=""
                      options={trueOrFalseOptions ?? []}
                    />
                  ) : (
                    <InputField
                      extraInputClass={
                        'border dark:bg-night-100 focus:border border-[#525151]'
                      }
                      label={'Data Property Value'}
                      placeholder={''}
                      register={register}
                      name={`condition.${index}.condition_value`}
                      extraLabelClass={'font-medium !text-sm dark:!text-white'}
                      variant="workflow"
                    />
                  )}
                </>
              ) : (
                <InputField
                  extraInputClass={
                    'border dark:bg-night-100 focus:border border-[#525151]'
                  }
                  label={'Data Property Value'}
                  placeholder={'Enter Value'}
                  register={register}
                  name={`condition.${index}.condition_value`}
                  extraLabelClass={'font-medium !text-sm dark:!text-white'}
                  variant="workflow"
                />
              )}
              {errors?.condition?.[index]?.condition_value && (
                <ErrorBox error={errors?.condition?.[index]?.condition_value} />
              )}
            </div>

            <div className="flex gap-2">
              <button
                className="bg-white p-2 rounded-md text-black px-4 disabled:opacity-60 disabled:cursor-not-allowed"
                type="button"
                onClick={onSubmit}
                disabled={!isValid}
              >
                Add condition
              </button>

              <button
                type="button"
                onClick={handleCancel}
                className="bg-night-400 text-white border border-gray-440 px-4 py-2 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
              >
                cancel
              </button>
            </div>
          </div>
        )}

        <div>
          {Boolean(selectedCondition?.condition_step_join_operator) && (
            <div
              className={cn(
                `mt-2 flex relative top-3`,
                selectedCondition?.condition_step_join_operator === '||'
                  ? '-left-5'
                  : '',
              )}
            >
              {
                (truthyDescription as any)[
                  selectedCondition?.condition_step_join_operator
                ]
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const WorkflowConditionForm = ({ branchIndex }: { branchIndex?: string }) => {
  const { selectedNode, workflowJson } = useWorkflowStore(
    useShallow(state => state),
  );

  const selectedNodeJson = findSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId: selectedNode?.id,
  });

  const triggerCondition = branchIndex
    ? selectedNodeJson?.branches?.[Number(branchIndex)]?.triggerCondition
    : selectedNodeJson?.triggerCondition;

  const formMethods = useForm<FormWorkflowConditions>({
    mode: 'onChange',
    resolver: yupResolver(workflowConditionSchema),
  });
  const { setValue } = formMethods;

  const fieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: 'condition',
  });

  React.useEffect(() => {
    const conditionExpressions = getMultipleExpressionValues(triggerCondition);
    const emptyConditionExpressions = conditionExpressions.length === 0;

    setTimeout(() => {
      setValue(
        'condition',
        emptyConditionExpressions
          ? [
              {
                ...defaultConditionExpressions[0],
                showForm: branchIndex ? true : false,
              },
            ]
          : (conditionExpressions as WorkflowConditions[]),
      );
    }, 0);
  }, [setValue, triggerCondition, branchIndex]);

  return (
    <div className={cn('mt-2 flex flex-col gap-2', branchIndex ? 'mt-0' : '')}>
      {!branchIndex && (
        <>
          <div className="flex justify-between items-center">
            <div className="text-lg font-medium">Conditional Execution</div>
            <LearnMore link={workflowDocLinks.condition_builder} />
          </div>

          <div className="text-[#ABB0B8] font-medium">
            {Boolean(triggerCondition)
              ? 'This step should be executed when:'
              : 'Specify conditions for this step to execute'}
          </div>
        </>
      )}

      <div className="flex flex-col gap-3">
        {fieldArrayMethods?.fields?.map((field, index) => (
          <WorkflowCondition
            key={field.id}
            index={index}
            triggerCondition={triggerCondition}
            formMethods={formMethods}
            fieldArrayMethods={fieldArrayMethods}
            branchIndex={branchIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default WorkflowConditionForm;
