import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

const get = async ({ queryKey }: QueryFunctionContext<[string, string]>) => {
  const [, invitationCode] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['invitations']}/${invitationCode}/accept`,
  );
  return data;
};

export const getAcceptInvitation = async (invitationCode: string) => {
  const { data } = await axiosInstance.get(
    `${routes['invitations']}/${invitationCode}/accept`,
  );
  return data;
};

const useGetAcceptInvitation = (
  invitationCode: string,
  enabled: boolean,
  onSuccess: () => void,
  onError: () => void,
) => {
  return useQuery({
    queryKey: [`${routes['invitations']}/accept`, invitationCode],
    queryFn: get,
    enabled,
    onSuccess,
    onError,
  });
};

export default useGetAcceptInvitation;
