import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PatchUserDetails = {
  code?: number;
  type: 'enable' | 'disable';
};

export interface TwoFactorEnableResponse {
  message: string;
  recoveryCodes: string[];
}

const patch = async (values: PatchUserDetails) => {
  const { type, ...data } = values;
  return axiosInstance.patch<TwoFactorEnableResponse>(
    `${routes['2fa']}/${type}`,
    data,
  );
};

const usePatch2Fa = () => {
  return useMutation({
    mutationFn: patch,
  });
};

export default usePatch2Fa;
