import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PatchClientUser = {
  clientId: number;
  name: string;
};

const patch = async (values: PatchClientUser) => {
  const { clientId, ...data } = values;
  return axiosInstance.patch(`${routes['clients']}/${clientId}`, data);
};

const usePatchClientUser = () => {
  return useMutation({
    mutationFn: (values: PatchClientUser) => patch(values),
  });
};

export default usePatchClientUser;
