import { useDisclosure } from '@chakra-ui/hooks';
import { GetScheduledNotifications } from 'api/scheduled/types';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { format, parseISO } from 'date-fns';
import { BellDot } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useScheduledStore } from 'store/scheduledStore';
import DeleteScheduledModal from './DeleteModal';
import DetailsModal from './DetailsModal';

function ScheduledTable(props: {
  tableData: any;
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  const { tableData, editOpen: onOpen } = props;
  const deleteScheduleModalActions = useDisclosure();
  const detailsModalActions = useDisclosure();
  const { setScheduled } = useScheduledStore(state => state);
  const navigate = useNavigate();

  type ScheduleAction = 'delete_schedule' | 'view_schedule';

  const handleMenuClick = (
    selected: GetScheduledNotifications,
    actionType: ScheduleAction,
  ) => {
    setScheduled(selected);

    if (actionType === 'view_schedule') {
      return detailsModalActions.onOpen();
    }

    if (actionType === 'delete_schedule') {
      return deleteScheduleModalActions.onOpen();
    }

    onOpen();
  };

  const columnConfig: ColumnConfig<GetScheduledNotifications> = [
    {
      accessorKey: 'id',
      header: 'ID',
      cellFunction: info => info.getValue(),
      size: 50,
    },
    {
      accessorKey: 'notificationPayload',
      header: 'Notification Type',
      cellFunction: info => {
        const notificationPayload = info.row.original.notificationPayload;
        const type = notificationPayload?.workflowId
          ? 'workflow'
          : notificationPayload?.templateId
            ? 'template'
            : 'normal';

        return type;
      },
    },
    {
      accessorKey: 'sendAt',
      header: 'Scheduled To Be Sent At',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMM yyyy, h:mm aaa');
        return date;
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Action',
      cellFunction: info => {
        return (
          <TableMenu
            config={[
              {
                text: 'View',
                action: () =>
                  handleMenuClick(info.row.original, 'view_schedule'),
              },
              {
                text: 'Delete',
                action: () =>
                  handleMenuClick(info.row.original, 'delete_schedule'),
              },
            ]}
          />
        );
      },
    },
  ];

  const columns = columnGenerator<GetScheduledNotifications>({ columnConfig });
  // const { pageIndex, pageSize, setPagination } = usePaginationState();

  return (
    <>
      <DeleteScheduledModal
        isOpen={deleteScheduleModalActions.isOpen}
        onClose={deleteScheduleModalActions.onClose}
      />

      <DetailsModal
        isOpen={detailsModalActions.isOpen}
        onClose={detailsModalActions.onClose}
      />

      {/* table */}
      <CustomTable
        tableData={tableData}
        columns={columns}
        emptyTableMessage="There are no scheduled notifications at this time"
        loading={props.loading}
        extraTdClass={'hover:cursor-pointer'}
        paginationType="table"
        emptyAdditionalJsx={() => (
          <EmptyTableMessage
            handleCreate={() => {
              navigate('/admin/compose/send');
            }}
            searchInput={''}
            docsLink={
              'https://docs.engagespot.co/docs/rest-api/#tag/Notifications/paths/~1v3~1notifications/post'
            }
            heading={"You haven't created any schedule notifications yet"}
            message={
              'Workflows in engagespot are the foundation of your notification system. You can send your first workflow.'
            }
            searchEmptyHeading={getSearchEmptyHeading('notifications')}
            searchEmptyMessage={getSearchEmptyMessage('notifications')}
            Icon={BellDot}
          />
        )}
      />
    </>
  );
}

export default ScheduledTable;
