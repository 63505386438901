import ApiKey from './components/ApiKey';
import GetStarted from './components/GetStarted';
import Hmac from './components/Hmac';
import CodeBlocks from './components/CodeBlocks';
import useGetAppDetails from 'api/app/get-app-details';
import { useAuthStore } from 'store/authStore';
import useGetAppSecret from 'api/app/get-app-secret';
import SecureAuthMode from './components/SecureAuthMode';

const ApiCredentials = () => {
  const { appId } = useAuthStore(state => state);
  const getAppDetails = useGetAppDetails(appId);
  const getAppSecret = useGetAppSecret(appId);

  return (
    <div className="flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          <div className="mb-5">
            <div className="col-span-6 flex h-full w-full flex-col gap-[22px] rounded-xl 3xl:col-span-4">
              <GetStarted />
              <ApiKey
                apiKey={getAppDetails.data?.apiKey}
                apiSecret={getAppSecret.data?.apiSecret}
                isLoaded={getAppDetails.isFetched}
              />
              <SecureAuthMode
                appId={appId}
                isEnabled={getAppDetails.data?.secureAuth}
              />
              <Hmac
                appId={appId}
                isEnabled={getAppDetails.data?.enableUserSignatureVerification}
              />
              <CodeBlocks
                heading="In-App Inbox Component"
                initial="react"
                listOfButtons={['react', 'nextjs', 'HTML_JS', 'angular']}
                text="Engagespot provides fully customisable ready-to-use in-app inbox for your app. UIKit is available for popular frameworks like React, Angular, Vue and other Javascript apps"
                link="https://docs.engagespot.com/docs/features/in-app-inbox/introduction"
                apiKey={getAppDetails.data?.apiKey}
                apiSecret={getAppSecret.data?.apiSecret}
              />
              <CodeBlocks
                heading="Sending a Notification"
                initial="bash"
                listOfButtons={['bash', 'node', 'php', 'python', 'ruby']}
                text="You can use Engagespot REST API or an SDK of your preferred language to send notifications programatically from your application code."
                link="https://docs.engagespot.co/docs/introduction/sending-notifications"
                apiKey={getAppDetails.data?.apiKey}
                apiSecret={getAppSecret.data?.apiSecret}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiCredentials;
