import { useStore } from '@nanostores/react';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { GetTemplateContent } from 'api/templates/get-template/types';
import usePostTemplateState from 'api/templates/post-template-state';
import {
  UpdateTemplatePutReturnType,
  updateTemplates,
} from 'api/templates/update-templates';
import { useEditWorkflowJson, useSaveWorkflowStateSpec } from 'api/workflows';
import { AxiosResponse } from 'axios';
import React from 'react';
import {
  FieldValues,
  SubmitHandler,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTemplateStore } from 'store/templateStore';
import { allTemplateDetails } from 'templates';
import {
  $currentTemplate,
  $templateConfigCurrentState,
  $templateConfigValues,
} from 'templates/store';
import { DEFAULT_TEMPLATE_STATE } from 'templates/utils';
import { capitalizeFirstLetter } from 'utils/functions';
import { R } from 'utils/remeda-utils';
import useCustomToast from 'utils/use-toast';
import { setUpdatedWorkflowJson } from '../../workflowEditor/functions';
import { getTemplateData, isFileThenConvertToUrl } from '../functions';
import { useGetEditorType } from '../hooks/getEditorType';
import { useGetFilteredTemplateConfigStates } from '../hooks/getFilteredTemplateConfigStates';
import { useGetTemplateChannelData } from '../hooks/getTemplateChannelData';
import {
  TemplateEditorAvailabeChannels,
  TemplateInputs,
  templateEditorInputConfig,
} from '../variables/inputs';
import TemplateEditorInputFields from './TemplateEditorInputFields';

const TemplateEditorInputs = ({
  saveRef,
  form,
}: {
  saveRef: React.MutableRefObject<HTMLButtonElement>;
  form: UseFormReturn<FieldValues, any>;
}) => {
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const templateId = params.templateId;
  const templateIdentifier = params.templateIdentifier;
  const { editorTypeTemplate, editorTypeWorkflow } = useGetEditorType();

  const { loading, setLoading, tab } = useTemplateStore(state => state);
  const queryClient = useQueryClient();
  const toast = useCustomToast();
  const templateConfigState = useStore($templateConfigCurrentState);
  const currentTemplate = useStore($currentTemplate);
  const templateConfigValues = useStore($templateConfigValues);
  const postTemplateState = usePostTemplateState();
  const saveWorkflowStateSpec = useSaveWorkflowStateSpec();
  const filteredTemplateConfigAllStates = useGetFilteredTemplateConfigStates();
  const getTemplateContent = useGetTemplateChannelData();
  const editWorkflowJson = useEditWorkflowJson();
  const savedTemplateData = React.useMemo(
    () => getTemplateContent,
    [getTemplateContent],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    setError,
    clearErrors,
  } = form;
  const isBatchingEnabled = useWatch({
    control,
    name: 'batchingEnabled',
  });
  const isUsingTwilioProvider = useWatch({
    control,
    name: 'usingTwilioProvider',
  });
  const messageType = useWatch({
    control,
    name: 'messageType',
  });
  const batchingMessageType = useWatch({
    control,
    name: 'batchingMessageType',
  });

  // This useEffect is for filling the inputs onLoad
  // This useEffect also fires after onSubmit and resets data to latest
  React.useEffect(() => {
    // sometimes content and  batchingContent strings, sometimes objects
    // we need to handle both cases
    // in batchingContent case we also need to add batching infront of keys
    // Eg: content -> batchingContent, since this is name in the form

    // initial file values, for whatsapp
    const defaultFileValues = {
      url_select: 'url',
      batchingUrl_select: 'url',
    };
    reset(defaultFileValues);

    if (!savedTemplateData) return;

    const { content, batchingContent, ...rest } = savedTemplateData;
    const inAppSpec =
      savedTemplateData?.states?.[0]?.stateSpec?.[DEFAULT_TEMPLATE_STATE];
    const inAppTemplateWithoutStates = savedTemplateData?.states?.length === 0;

    const inAppTemplateWithoutStatesValues = Object.fromEntries(
      Object.entries(content ?? {})?.map(([key, value]) => {
        return [`${DEFAULT_TEMPLATE_STATE}_${key}`, value];
      }),
    );

    const batchingContentWithCorrectKeys =
      batchingContent &&
      Object.fromEntries(
        Object.entries(batchingContent)?.map(([key, value]) => {
          const capitalisedKey = capitalizeFirstLetter(key);
          return [`batching${capitalisedKey}`, value];
        }),
      );

    reset({
      ...rest,
      ...savedTemplateData,
      ...(typeof content === 'object' && content),
      ...(inAppSpec ?? {}),
      ...(typeof batchingContentWithCorrectKeys === 'object' &&
        batchingContentWithCorrectKeys),
      ...defaultFileValues,
      ...(channel === 'sms' && {
        content: typeof content === 'string' ? content : content?.message,
        batchingContent:
          typeof batchingContent === 'string'
            ? batchingContent
            : batchingContent?.message,
      }),
      ...(channel === 'whatsapp' && {
        url: (content as any)?.[`${(content as any)?.['messageType']}Url`],
        batchingUrl: (batchingContent as any)?.[
          `${(batchingContent as any)?.['messageType']}Url`
        ],
        usingTwilioProvider: Boolean(content?.messagingServiceSid),
      }),
      ...(channel === 'discord' && {
        embeds: JSON.stringify(
          (content as GetTemplateContent)?.embeds,
          undefined,
          4,
        ),
        batchingEmbeds: JSON.stringify(
          (batchingContentWithCorrectKeys as GetTemplateContent)
            ?.batchingEmbeds,
          undefined,
          4,
        ),
      }),
      ...(channel === 'slack' && {
        blocks: JSON.stringify(
          (content as GetTemplateContent)?.blocks,
          undefined,
          4,
        ),
        batchingBlocks: JSON.stringify(
          (batchingContentWithCorrectKeys as GetTemplateContent)
            ?.batchingBlocks,
          undefined,
          4,
        ),
      }),
      ...(channel === 'inApp' &&
        inAppTemplateWithoutStates &&
        inAppTemplateWithoutStatesValues),
    });
  }, [channel, reset, savedTemplateData]);

  /**
   * ******** IMPORTANT **************
   * After submit request, the form is reset to make sure isDirty is null,
   * so that unsaved modal does not popup. So make sure to invalidate and
   * refetch the get apis that is used to refill the form.
   * ******** IMPORTANT **************
   */
  const onSubmit: SubmitHandler<any> = async values => {
    try {
      setLoading(true);

      const getTemplateDataResponse = await getTemplateData({
        values,
        channel,
        tab,
        filteredTemplateConfigAllStates,
        templateIdentifier,
        templateConfigValues,
        currentTemplate,
        toast,
        setLoading,
      });
      const { templateData, batchedTemplateData } = getTemplateDataResponse;

      if (!templateData || !batchedTemplateData) {
        setLoading(false);
        return toast.error('channel not configured in frontend');
      }

      let editorTypeTemplateResponse;
      if (editorTypeTemplate) {
        editorTypeTemplateResponse = await updateTemplates({
          id: templateId,
          channelType: channel,
          data: templateData,
          type: 'put_content',
        });
      }

      // if (editorTypeWorkflow) {
      //   workflowTemplateEditorUpdateJson({
      //     templateIdentifier,
      //     template: templateData.content,
      //   });
      // }

      // also saving state of different template inputs
      if (channel === 'inApp') {
        const rawData = (getTemplateDataResponse as any)?.rawData;
        // we also need to handle the case where we go from using a template to default inApp
        // if no template is selected simply sent empty string or 0

        /**
         * all our inputs are of the format ${state}_inputName
         * eg: default_button_label, accept_button_label
         * so need need to seperate it into different keys and do additonal operations
         * simply store all values into "default" key and fetch it
         */
        const defaultTemplateValues =
          allTemplateDetails?.[currentTemplate]['defaultValues'];

        const updatedTemplateConfigValues = Object.keys(
          templateConfigValues,
        ).reduce((acc, curr) => {
          const value = R.isBoolean(rawData?.[curr])
            ? rawData?.[curr]
            : Boolean(rawData?.[curr])
              ? rawData?.[curr]
              : defaultTemplateValues[curr];

          return {
            ...acc,
            [curr]: value,
          };
        }, {});

        await isFileThenConvertToUrl({
          data: updatedTemplateConfigValues,
          toast,
          channel,
          setLoading,
        });

        if (editorTypeTemplate) {
          await postTemplateState.mutateAsync({
            templateId,
            state: DEFAULT_TEMPLATE_STATE,
            templateContentId: Number(
              (
                editorTypeTemplateResponse as AxiosResponse<UpdateTemplatePutReturnType>
              )?.data?.id ?? 0,
            ),
            stateSpec: {
              [DEFAULT_TEMPLATE_STATE]: updatedTemplateConfigValues,
              templateId: currentTemplate,
            },
          });
        }

        if (editorTypeWorkflow) {
          saveWorkflowStateSpec.mutate({
            workflowId: templateId,
            data: {
              ref: templateIdentifier,
              state: DEFAULT_TEMPLATE_STATE,
              stateSpec: {
                [DEFAULT_TEMPLATE_STATE]: updatedTemplateConfigValues,
                templateId: currentTemplate,
              },
            },
          });
        }
      }

      if (editorTypeTemplate) {
        await updateTemplates({
          id: templateId,
          channelType: channel,
          data: {
            batchingEnabled: isBatchingEnabled,
            batchingWindow: parseInt(values.batchingWindow),
          },
          type: 'patch_content',
        });

        if (isBatchingEnabled) {
          await updateTemplates({
            id: templateId,
            channelType: channel,
            data: batchedTemplateData,
            type: 'put_batching_content',
          });
        }

        // TODO: optimise this
        // Currently just quick fix for showing batching enabled green dot in templateHeader
        queryClient.invalidateQueries({
          queryKey: [`${routes['templates']}`],
        });
      }

      if (editorTypeWorkflow) {
        await setUpdatedWorkflowJson({
          templateIdentifier,
          template: templateData.content,
          editWorkflowJson,
          templateId,
        });
      }

      toast.success('template saved successfully');

      // form is reset, to make isDirty false
      // make sure to refetch get apis to refill form
      reset();

      setTimeout(() => {
        setLoading(false);
      }, 850);
    } catch (e) {
      toast.showError(e);
      setLoading(false);
    }
  };

  const config =
    channel === 'inApp' && currentTemplate && tab !== 'batched_template'
      ? allTemplateDetails?.[currentTemplate]?.['consoleInputs']?.[
          templateConfigState
        ]?.[tab]
      : templateEditorInputConfig[channel][tab];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[55%] flex-col gap-3 "
    >
      {(config as TemplateInputs[])?.map((option, index) => (
        <TemplateEditorInputFields
          key={index}
          batchingMessageType={batchingMessageType}
          channel={channel}
          control={control}
          register={register}
          errors={errors}
          isBatchingEnabled={isBatchingEnabled}
          messageType={messageType}
          option={option}
          setValue={setValue}
          tab={tab}
          clearErrors={clearErrors}
          isUsingTwilioProvider={isUsingTwilioProvider}
          setError={setError}
        ></TemplateEditorInputFields>
      ))}

      {/* submit */}
      <button disabled={loading} hidden={true} ref={saveRef}>
        submit
      </button>
    </form>
  );
};

export default TemplateEditorInputs;
