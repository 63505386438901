export {};

declare global {
  interface Window {
    _env_: any;
  }
}

export const API_BASE_URL =
  window._env_.VITE_REACT_APP_BASE_URL ||
  import.meta.env.VITE_REACT_APP_BASE_URL ||
  'http://' + window.location.hostname + ':9700';
export const API_LICENSING_URL =
  window._env_.VITE_REACT_APP_LICENSING_URL ||
  import.meta.env.VITE_REACT_APP_LICENSING_URL ||
  'https://licensing.engagespot.co';
export const ENGAGESPOT_API_KEY = import.meta.env
  .VITE_REACT_APP_ENGAGESPOT_API_KEY;
export const ENGAGESPOT_BASE_URL = import.meta.env
  .VITE_REACT_APP_ENGAGESPOT_BASE_URL;
export const ENGAGESPOT_ENDPOINT_OVERRIDE = import.meta.env
  .VITE_REACT_APP_ENGAGESPOT_ENDPOINT_OVERRIDE;
export const GITHUB_APP_CLIENT_ID = import.meta.env
  .VITE_REACT_APP_GITHUB_APP_CLIENT_ID;
export const GITHUB_APP_REDIRECT_URL = import.meta.env
  .VITE_REACT_APP_GITHUB_APP_REDIRECT_URL;
export const STRIPE_RETURN_URL = import.meta.env
  .VITE_REACT_APP_STRIPE_RETURN_URL;
export const STRIPE_API_KEY = import.meta.env.VITE_REACT_APP_STRIPE_API_KEY;
export const ANALYTICS_MIN_DATE =
  import.meta.env.VITE_REACT_APP_ANALYTICS_MIN_DATE || '06-5-2024'; // May 6 2024 (Analytics tracking start);
export const DEFAULT_WORKFLOW =
  import.meta.env.VITE_REACT_APP_DEFAULT_WORKFLOW || 'default_welcome_workflow';
export const APP_TO_ENV_VERSION =
  import.meta.env.VITE_REACT_APP_TO_ENV_VERSION || '1.5';
export const GOOGLE_RECAPTCHA_SITE_KEY = import.meta.env
  .VITE_REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
export const WORKFLOW_NEW_EMAIL_EDITOR_LAUNCH_VERSION =
  import.meta.env.VITE_REACT_WORKFLOW_NEW_EMAIL_EDITOR_LAUNCH_VERSION ||
  '2.0.0';
