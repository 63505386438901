import Cookies from 'js-cookie';
import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

export interface Post2FaRecoveryCode {
  email: string;
  password: string;
  [x: string | 'code' | 'recoveryCode']: string | number;
}

interface Post2FaRecoveryCodeResponse {
  token: string;
}

const post = async (values: Post2FaRecoveryCode) => {
  return axiosInstance.post<Post2FaRecoveryCodeResponse>(
    `${routes['2fa']}/recovery`,
    values,
  );
};

const usePost2FaRecoveryCode = () => {
  return useMutation({
    mutationFn: post,
    onSuccess: response => {
      const token = response.data.token;
      Cookies.set('access_token', token);
    },
  });
};

export default usePost2FaRecoveryCode;
