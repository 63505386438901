import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'components/shadcn/tabs';
import { useDisclosure } from '@chakra-ui/hooks';
import useGetProviders from 'api/providers/get-providers';
import Card from 'components/card';
import Dash from 'components/dash';
import LoaderModal from 'components/loader/LoaderModal';
import React from 'react';
import { useParams } from 'react-router-dom';
import ProvidersCard from './components/ProvidersCard';
import ChannelRoutesModal from './components/ChannelRoutesModal';
import useGetChannelRoutes from 'api/channel-routes/get-channel-routes';
import { useAuthStore } from 'store/authStore';
import ChannelRouteCard from './components/ChannelRouteCard';
import DeleteChannelRouteModal from './components/DeleteChannelRouteModal';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';
import PageHeader from 'components/header/PageHeader';

const Providers = () => {
  const loaderModalActions = useDisclosure();
  const params = useParams();
  const channel = params.channel;
  const getProviders = useGetProviders(channel);
  const { appId } = useAuthStore(state => state);
  const getChannelRoutes = useGetChannelRoutes({
    appId,
    channel,
    enabled: true,
  });

  const channelsAddedInRoutes = getChannelRoutes?.data?.flatMap(route => {
    return route.routeProviders.map(provider => provider.providerInstance.id);
  });

  const configuredProviders = getProviders.data?.configuredProviders;
  const availableProviders = getProviders.data?.availableProviders;

  const channelRoutesModalActions = useDisclosure();
  const deleteChannelRoutesModalActions = useDisclosure();

  return (
    <>
      {!getProviders.isFetched && (
        <LoaderModal
          isOpen={getProviders.isLoading}
          onClose={loaderModalActions.onClose}
        />
      )}

      <ChannelRoutesModal
        isOpen={channelRoutesModalActions.isOpen}
        onClose={channelRoutesModalActions.onClose}
      />

      <DeleteChannelRouteModal
        isOpen={deleteChannelRoutesModalActions.isOpen}
        onClose={deleteChannelRoutesModalActions.onClose}
      />

      {/* Header */}
      <PageHeader
        heading={
          <>
            <h2 className=" text-2xl font-semibold capitalize text-white">
              {channel}
            </h2>
          </>
        }
        text={<p className="text-sm text-gray-650">Providers</p>}
      />

      {/* Content */}
      <Card extra={'pb-8 px-6 pt-6 w-full'}>
        <Tabs defaultValue="1" className="">
          {/* Switch Buttons */}
          <div className="mb-8 flex w-full justify-between pt-6">
            <TabsList>
              <TabsTrigger value="1">Providers</TabsTrigger>
              <TabsTrigger value="2">Routes</TabsTrigger>
            </TabsList>

            <TabsContent value="2">
              <div>
                <button
                  onClick={() => channelRoutesModalActions.onOpen()}
                  className={cn(
                    buttonVariants({
                      size: 'default',
                      className: 'rounded-md text-base',
                    }),
                  )}
                >
                  Add New Route
                </button>
              </div>
            </TabsContent>
          </div>

          <TabsContent value="1">
            <>
              <p className="relative bottom-2 text-sm text-gray-650">
                Providers are third-party service that enables communication
                over a channel.{' '}
                <a
                  rel="noreferrer"
                  href="https://docs.engagespot.com/docs/features/channels-and-providers/enabling-a-channel"
                  className="text-decoration-line: underline"
                  target="_blank"
                >
                  Learn more
                </a>
              </p>
              {/* Configured */}
              {Boolean(configuredProviders?.length) && (
                <div
                  className={Boolean(availableProviders?.length) ? `mb-10` : ''}
                >
                  <div className="flex items-center gap-2">
                    <h4 className="text-lg font-medium">Configured</h4>
                    <Dash />
                  </div>

                  {/* card container */}
                  <div className="mt-6 grid gap-5 xl:grid-cols-2 2xl:grid-cols-3 3xl:gap-6">
                    {configuredProviders?.map(item => (
                      <ProvidersCard
                        isAddedInRoute={channelsAddedInRoutes?.includes(
                          item.id,
                        )}
                        provider={item}
                        providerIdentifier={item.provider.identifier}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Available */}
              {Boolean(availableProviders?.length) && (
                <div>
                  {/* Heading */}
                  <div className="flex items-center gap-2">
                    <h4 className="text-lg font-medium">Available</h4>
                    <Dash />
                  </div>

                  {/* card container */}
                  <div className="mt-6 grid gap-5 xl:grid-cols-2 2xl:grid-cols-3 3xl:gap-6">
                    {availableProviders?.map(item => (
                      <ProvidersCard
                        firstConfiguredProvider={
                          configuredProviders?.length === 0
                        }
                        provider={item}
                        providerIdentifier={item.identifier}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          </TabsContent>

          <TabsContent value="2">
            <>
              <div>
                <p className="text-sm text-gray-650">
                  With smart routing, you can configure multiple providers in a
                  channel and selectively use them for your recipients.{' '}
                  <a
                    rel="noreferrer"
                    href="https://docs.engagespot.com/docs/features/channels-and-providers/multiple-providers-and-routes"
                    className="text-decoration-line: underline"
                    target="_blank"
                  >
                    Learn more
                  </a>
                </p>
              </div>
              <Dash />
              <div className="mt-6 grid gap-5 xl:grid-cols-2 2xl:grid-cols-3 3xl:gap-6">
                {getChannelRoutes.data?.map(route => (
                  <ChannelRouteCard
                    route={route}
                    handleDeleteModalOpen={
                      deleteChannelRoutesModalActions.onOpen
                    }
                    handleEditModalOpen={channelRoutesModalActions.onOpen}
                    key={route.id}
                  ></ChannelRouteCard>
                ))}
              </div>
            </>
          </TabsContent>
        </Tabs>
      </Card>
    </>
  );
};

export default Providers;
