import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';

type PutClientUser = {
  clientUserId: number;
  role: string;
};

const put = async (values: PutClientUser, clientId: number) => {
  const { clientUserId, ...data } = values;

  return axiosInstance.put(
    `${routes['clients']}/${clientId}/clientUsers/${clientUserId}`,
    data,
  );
};

const usePutClientUser = () => {
  const { clientId } = useAuthStore(state => state);

  return useMutation({
    mutationFn: (values: PutClientUser) => put(values, clientId),
  });
};

export default usePutClientUser;
