import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PatchUserDetails = {
  clientId: number;
  profilePicture?: string;
  firstName?: string;
  lastName?: string;
  timezone?: string;
};

const patch = async (values: PatchUserDetails) => {
  const { clientId, ...data } = values;
  return axiosInstance.patch(`${routes['clientUsers']}/${clientId}`, data);
};

const usePatchUserDetails = () => {
  return useMutation({
    mutationFn: patch,
  });
};

export default usePatchUserDetails;
