import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import Cookies from 'js-cookie';

const post = async <T>(values: T & { redirect: string }) => {
  const { redirect = '', ...data } = values;
  return axiosInstance.post(`${routes['signup']}${redirect}`, data);
};

const usePostSignUp = <T>() => {
  return useMutation({
    mutationFn: post<T>,
    onSuccess: response => {
      const token = response.data.token;

      if (token) {
        Cookies.set('access_token', token);
      }
    },
  });
};

export default usePostSignUp;
