import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons';
import * as React from 'react';

import { Button } from 'components/shadcn/button';

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/shadcn/command';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';

import { useDisclosure } from '@chakra-ui/hooks';
import useGetClientApps from 'api/client/get-client-apps';
import { SelectOptions } from 'components/fields/SelectField/types';
import { useAuthStore } from 'store/authStore';
import { cn } from 'utils/class-merge';
import { usePlanLimit } from 'utils/use-plan-limit';
import NavbarModal from './modal';
import { useIsOldVersion } from 'utils/use-old-version';

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface AppSwitcherProps extends PopoverTriggerProps {
  className?: string;
}

export default function AppSwitcher({ className }: AppSwitcherProps) {
  const [open, setOpen] = React.useState(false);
  const planLimit = usePlanLimit();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { clientId, setAppId, appId } = useAuthStore(state => state);
  const getClientApps = useGetClientApps(clientId);
  const { isOldVersion } = useIsOldVersion();
  const apps = getClientApps?.data?.reduce((acc, curr) => {
    return [
      ...acc,
      {
        label: curr.name,
        value: String(curr.id),
      },
    ];
  }, [] as SelectOptions[]);

  const [selectedApp, setSelectedApp] = React.useState<SelectOptions>();

  const handleAddApp = () => {
    if (planLimit.limits.noOfApps <= getClientApps.data.length) {
      planLimit.actions.onOpen();
      return planLimit.actions.setText(planLimit.texts.no_exceeded);
    }

    onOpen();
  };

  React.useEffect(() => {
    if (getClientApps.isLoading) return;

    const currentApp = getClientApps?.data?.find(app => app.id === appId);

    if (currentApp) {
      setSelectedApp({
        label: String(currentApp.name),
        value: String(currentApp.id),
      });
    }
  }, [appId, getClientApps?.data, getClientApps.isLoading]);

  return (
    <>
      <NavbarModal
        setSelectedApp={setSelectedApp}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select an app"
            className={cn(
              'w-[200px] text-white hover:bg-muted/50  justify-between border border-[#3A3F47]',
              className,
            )}
          >
            {selectedApp?.label}
            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandGroup heading={isOldVersion ? 'Apps' : 'Environments'}>
                <CommandItem value="-" className="hidden" />
                {apps?.map(team => (
                  <CommandItem
                    key={team.value}
                    onSelect={() => {
                      setSelectedApp(team);
                      setOpen(false);
                      setAppId(Number(team.value));
                    }}
                    className={cn(
                      'text-sm',
                      selectedApp?.value === team.value && 'bg-muted/50',
                    )}
                  >
                    {team.label}
                    <CheckIcon
                      className={cn(
                        'ml-auto h-4 w-4',
                        selectedApp?.value === team.value
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>

            <CommandSeparator />

            <CommandList>
              <CommandGroup>
                <CommandItem
                  onSelect={() => {
                    setOpen(false);
                    handleAddApp();
                  }}
                >
                  <PlusCircledIcon className="mr-2 h-5 w-5" />
                  {isOldVersion ? 'Create App' : 'Create Environment'}
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
