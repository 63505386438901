import { yupResolver } from '@hookform/resolvers/yup';
import { usePostAddWorkspace } from 'api/workspace';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { SelectOptions } from 'components/fields/SelectField/types';
import HeaderDoc from 'components/header/HeaderDoc';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { cn } from 'utils/class-merge';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type FormAddWorkspace = {
  name: string;
};

export const schema = yup.object({
  name: yup
    .string()
    .required('name is required')
    .matches(
      /^[a-zA-Z0-9_ ]*$/,
      'name can only contain letters, numbers, underscores, and spaces',
    )
    .min(2, 'name must be at least 2 characters'),
});

function AddWorkspaceModal({
  isOpen,
  onClose,
  setSelectedWorkSpace,
  handleSwitchWorkSpaces,
}: {
  onClose: () => void;
  isOpen: boolean;
  setSelectedWorkSpace: React.Dispatch<React.SetStateAction<SelectOptions>>;
  handleSwitchWorkSpaces: (id: number) => void;
}) {
  const postAddWorkspace = usePostAddWorkspace();
  const toast = useCustomToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setFocus,
  } = useForm<FormAddWorkspace>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const handleClose = () => {
    clearErrors('name');
    reset({
      name: '',
    });
    onClose();
  };

  const onSubmit: SubmitHandler<FormAddWorkspace> = values => {
    const data = {
      name: values.name,
    };

    postAddWorkspace.mutate(data, {
      onSuccess: response => {
        const client = response.data?.client;

        setSelectedWorkSpace({
          label: String(client?.name),
          value: String(client?.id),
        });
        handleSwitchWorkSpaces(client?.id);
        toast.success('New workspace has been created');
        handleClose();
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setFocus('name');
      });
    }
  }, [setFocus, isOpen]);

  return (
    <CustomModal
      disabledModalCloseButton={postAddWorkspace.isLoading}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-4 text-2xl font-bold">Creat Workspace</h1>

      <p className={cn('text-muted-foreground text-sm mb-4')}>
        A workspace is a self-contained unit for organizing resources.
        <HeaderDoc
          link="https://docs.engagespot.co/docs/concepts/workspace"
          text="Learn more"
        />
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField<FormAddWorkspace>
          variant="modal"
          extra="mb-3"
          label={'Workspace Name'}
          placeholder="Enter workspace name (Eg: Marketing Team)"
          type="text"
          showIsRequiredAsterisk={true}
          register={register}
          name={'name'}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        <CustomModalButtons
          handleClose={handleClose}
          primaryButtonText="Create"
          loading={postAddWorkspace.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default AddWorkspaceModal;
