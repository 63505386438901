import { useDisclosure } from '@chakra-ui/hooks';
import { useGetEmailLayouts } from 'api/layouts';
import HeaderDoc from 'components/header/HeaderDoc';
import PageHeader from 'components/header/PageHeader';
import React from 'react';
import { useLayoutStore } from 'store/layoutStore';
import { useAppLevel } from 'utils/use-app-level';
import LayoutModal from './components/LayoutModal';
import LayoutTable from './components/LayoutTable';

const Layouts = () => {
  const [filter, setFilter] = React.useState('');
  const getLayouts = useGetEmailLayouts();
  const addLayoutModalActions = useDisclosure();
  const { setLayout, setLayoutModalState } = useLayoutStore(state => state);
  const { isDefaultApp } = useAppLevel();

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <LayoutModal
        isOpen={addLayoutModalActions.isOpen}
        onClose={addLayoutModalActions.onClose}
      />

      {/* Header */}
      <PageHeader
        setFilter={setFilter}
        filter={filter}
        paginationType={'table'}
        showSearchBar={false}
        handleCreate={() => {
          setLayout(null);
          setLayoutModalState('add');
          addLayoutModalActions.onOpen();
        }}
        buttonText={isDefaultApp ? 'Create Layout' : ''}
        heading={'Layouts'}
        text={
          <>
            Create common reusable layouts for your app.
            <HeaderDoc
              link="https://docs.engagespot.co/docs/features/layouts/overview"
              text="Learn more"
            />
          </>
        }
      />

      {/* Tables */}
      <LayoutTable
        loading={getLayouts.isLoading}
        tableData={getLayouts?.data ?? []}
        editOpen={addLayoutModalActions.onOpen}
        globalFilter={filter}
        setGlobalFilter={setFilter}
      />
    </div>
  );
};

export default Layouts;
