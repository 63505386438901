import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { Get2Fa } from './types';
import { routes } from 'api/routes';

const get = async (): Promise<Get2Fa> => {
  const { data } = await axiosInstance.get(`${routes['2fa']}`);

  return data;
};

const useGet2Fa = (enabled: boolean) => {
  return useQuery({
    queryKey: [routes['2fa']],
    queryFn: get,
    enabled,
  });
};

export default useGet2Fa;
