import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type DeleteClientUser = {
  clientUserId: number;
  clientId: number;
};

const remove = async (values: DeleteClientUser) => {
  const { clientId, clientUserId } = values;
  return axiosInstance.delete(
    `${routes['clients']}/${clientId}/clientUsers/${clientUserId}`,
  );
};

const useDeleteClientUser = () => {
  return useMutation({
    mutationFn: (values: DeleteClientUser) => remove(values),
  });
};

export default useDeleteClientUser;
