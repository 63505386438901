export const sources = [
  'Email',
  'Search',
  'Twitter',
  'ProductHunt',
  'Reddit',
  'Blog',
  'Bubble.io',
  'Friend',
  'Other',
];
