import { Calendar } from 'components/shadcn/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';
import { addMonths, format, isAfter } from 'date-fns';
import { Control, Controller } from 'react-hook-form';
import { LuCalendar as CalendarIcon, LuX as CloseIcon } from 'react-icons/lu';
import { cn } from 'utils/class-merge';
import { SubmitNotification } from 'views/admin/dashboards/compose/type';
import { TimePicker } from './time-picker';
import { isBefore, isToday } from 'date-fns';

export function DateTimePicker({
  control,
  label,
  helperText,
}: {
  control: Control<SubmitNotification, any>;
  label: string;
  helperText?: string;
}) {
  return (
    <>
      <Controller
        control={control}
        name="sendAt"
        render={({ field }) => (
          <div className="flex flex-col mb-2">
            <label className={`text-white ml-1.5  text-sm font-medium mb-2`}>
              {label}
            </label>

            {helperText && (
              <p
                className="ml-1 text-sm text-white opacity-[35%] relative bottom-1"
                dangerouslySetInnerHTML={{
                  __html: helperText,
                }}
              ></p>
            )}

            <Popover>
              <PopoverTrigger className="dark:!bg-night-100" asChild>
                <button
                  className={cn(
                    'dark:!bg-night-100 relative flex gap-2 items-center pl-3 h-12 rounded-md text-left disabled:opacity-60 border border-gray-930 dark:text-white dark:placeholder:!text-[#ABB0B8] w-full',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    format(field.value, 'PPP HH:mm:ss')
                  ) : (
                    <span>Pick a date</span>
                  )}

                  {field.value && (
                    <span className="absolute right-5 top-[30%]">
                      <CloseIcon
                        onClick={() => {
                          field.onChange(undefined);
                        }}
                      >
                        close
                      </CloseIcon>
                    </span>
                  )}
                </button>
              </PopoverTrigger>

              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={field.value}
                  onSelect={field.onChange}
                  initialFocus
                  disabled={date => {
                    const today = isToday(date);

                    const isBeforeToday = isBefore(date, new Date());

                    const oneMonthFromNow = addMonths(new Date(), 1);
                    const isAfterOneMonth = isAfter(date, oneMonthFromNow);

                    if (today) return false;

                    if (isBeforeToday) return true;

                    if (isAfterOneMonth) return true;
                  }}
                />
                <div className="p-3 border-t border-border">
                  <TimePicker setDate={field.onChange} date={field.value} />
                </div>
              </PopoverContent>
            </Popover>
          </div>
        )}
      />
    </>
  );
}
