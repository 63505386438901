import axios from 'axios';
import { API_BASE_URL } from 'config';
import {
  axiosRequestInterceptor,
  axiosResponseErrorInterceptor,
} from './common';

const baseURL = API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(axiosRequestInterceptor, err =>
  Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  response => response,
  axiosResponseErrorInterceptor,
);
