export const workflowContents = (args: {
  apiKey: string;
  apiSecret: string;
  workflowIdentifier: string;
  recipient: string;
}): any => {
  return {
    node: `const { EngagespotClient } = require('@engagespot/node');
const client = EngagespotClient({
  apiKey: '${args.apiKey}',
  apiSecret: '${args.apiSecret}',
});

client.send({
  notification: {
    workflow: {
      identifier: '${args.workflowIdentifier}',
    },
  },
  sendTo: {
    recipients: ['${args.recipient}'],
  },
});`,
    bash: `curl --location --request POST 'https://api.engagespot.co/v3/notifications' \\
--header 'X-ENGAGESPOT-API-SECRET: ${args.apiSecret}' \\
--header 'X-ENGAGESPOT-API-KEY: ${args.apiKey}' \\
--header 'Content-Type: application/json' \\
--data-raw '{ 
    "notification":{ 
      "workflow":{ 
          "identifier":"${args.workflowIdentifier}" 
      }
    },
    "sendTo":{ 
      "recipients":["${args.recipient}"] 
    }
}'`,
    python: `from engagespot import Engagespot
client = Engagespot(api_key="${args.apiKey}", api_secret="${args.apiSecret}")
send_request = {
    "notification": {
        "workflow":{
            "identifier":"${args.workflowIdentifier}"
        }
    },
    "sendTo":{
        "recipients": ["${args.recipient}"]
    }
}
client.send(send_request)`,
    php: `use Engagespot\\EngagespotClient;
$engagespot = new EngagespotClient('${args.apiKey}', '${args.apiSecret}');
$notificationData = [
  'notification' => [
      'workflow' => [
         'identifier' =>'${args.workflowIdentifier}'
      ],
  ],
  'sendTo' => [
    'recipients' => ['${args.recipient}'],
  ],
];
$engagespot->send($notificationData);
    `,
    ruby: `engagespot = Engagespot.new(api_key: '${args.apiKey}',
api_secret: '${args.apiSecret}')

send_request = {
  notification:{
      workflow: {
          identifier: "${args.workflowIdentifier}"
      },
  },
  sendTo:{
      recipients: ['${args.recipient}']
  }
}
engagespot.send(send_request)`,
  };
};
