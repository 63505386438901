import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { FormAddWorkflows } from 'views/admin/dashboards/workflow/types';
import {
  DuplicateWorkflow,
  EditWorkflowJson,
  MutateWorkflow,
  MutateWorkflowStateSpec,
  SaveEmailWorkflow,
  SaveEmailWorkflowResponse,
  ToggleWorkflow,
  WorkflowStateSpec,
} from './types';
import { GetWorkflowJson, GetWorkflows } from './types';

// get workflow
const getWorkflows = async ({
  queryKey,
}: QueryFunctionContext<[string, number, Record<string, string>]>): Promise<
  GetWorkflows[]
> => {
  const [, appId, params] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/workflows`,
    {
      params,
    },
  );
  return data;
};

export const useGetWorkflows = (params?: Record<string, string>) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/workflows`, appId, params],
    queryFn: getWorkflows,
    enabled: Boolean(appId),
  });
};

// get workflow json
const getWorkflowJson = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetWorkflowJson> => {
  const [, workflowId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['workflows']}/${workflowId}`,
  );
  return data;
};

export const useGetWorkflowJson = (workflowId: number, enabled = true) => {
  return useQuery({
    queryKey: [`${routes['workflows']}/json`, workflowId],
    queryFn: getWorkflowJson,
    enabled: Boolean(workflowId) && enabled,
    staleTime: 1000 * 5, // 5 seconds
  });
};

const getVersionedWorkflowJson = async ({
  queryKey,
}: QueryFunctionContext<[string, number, number]>): Promise<
  GetWorkflowJson['jsonSpec']
> => {
  const [, workflowId, version] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['workflows']}/${workflowId}/${version}`,
  );
  return data;
};

export const useGetVersionedWorkflowJson = (
  workflowId: number,
  version: number,
  enabled = true,
) => {
  return useQuery({
    queryKey: [`${routes['workflows']}/versioned`, workflowId, version],
    queryFn: getVersionedWorkflowJson,
    enabled: Boolean(workflowId && version) && enabled,
    staleTime: 1000 * 5, // 5 seconds
  });
};

// get workflow json
const getWorkflowStateSpec = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, string]
>): Promise<WorkflowStateSpec> => {
  const [, workflowId, ref] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['workflows']}/${workflowId}/step-state/${ref}`,
  );
  return data;
};

export const useGetWorkflowStateSpec = (
  workflowId: number,
  ref: string,
  enabled = true,
) => {
  return useQuery({
    queryKey: [`${routes['workflows']}/step-state`, workflowId, ref],
    queryFn: getWorkflowStateSpec,
    enabled: Boolean(workflowId && ref && enabled),
  });
};

// mutate - put and delete workflow
const mutateWorkflow = async (values: MutateWorkflow) => {
  const { workflowId, name, type } = values;

  const url = `${routes['workflows']}/${workflowId}`;

  const data = {
    name,
  };

  if (type === 'delete') {
    return axiosInstance.delete(url);
  }

  return axiosInstance.put(url, data);
};

export const useMutateWorkflow = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: mutateWorkflow,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/workflows`]);
    },
  });
};

// Duplicate Workflowk
const duplicateWorkflow = async (values: DuplicateWorkflow) => {
  const { workflowId, ...data } = values;
  const url = `${routes['workflows']}/${workflowId}/duplicate`;
  return axiosInstance.post(url, data);
};

export const useDuplicateWorkflow = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: duplicateWorkflow,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/workflows`]);
    },
  });
};

// edit workflow json
const editWorkflowJson = async (values: EditWorkflowJson) => {
  const { workflowId, jsonSpec } = values;

  const data = {
    jsonSpec,
  };

  return axiosInstance.patch(`${routes['workflows']}/${workflowId}`, data);
};

export const useEditWorkflowJson = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editWorkflowJson,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['workflows']}/json`]);
    },
  });
};

// create
const createWorkflow = async (values: FormAddWorkflows) => {
  const { appId, category, name, identifier } = values;

  const data = {
    categoryId: Number(category),
    name,
    identifier,
    jsonSpec: {
      name: name,
      steps: [] as any,
    },
  };

  return axiosInstance.post(`${routes['apps']}/${appId}/workflows`, data);
};

export const useCreateWorkflow = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createWorkflow,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/workflows`]);
    },
  });
};

// publish
const publishWorkflowJson = async (values: EditWorkflowJson) => {
  const { workflowId, jsonSpec } = values;

  const data = {
    jsonSpec,
  };

  return axiosInstance.post(
    `${routes['workflows']}/${workflowId}/publish`,
    data,
  );
};

export const usePublishWorkflowJson = () => {
  return useMutation({
    mutationFn: publishWorkflowJson,
  });
};

// toggle
const toggleWorkflowJson = async (values: ToggleWorkflow) => {
  const { workflowId } = values;
  return axiosInstance.post(`${routes['workflows']}/${workflowId}/status`, {});
};

export const useToggleWorkflowJson = () => {
  const { appId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: toggleWorkflowJson,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/workflows`, appId]);
    },
  });
};

// save spec
const saveWorkflowStateSpec = async (values: MutateWorkflowStateSpec) => {
  const { workflowId, data } = values;
  return axiosInstance.post(
    `${routes['workflows']}/${workflowId}/step-state`,
    data,
  );
};

export const useSaveWorkflowStateSpec = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: saveWorkflowStateSpec,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['workflows']}/step-state`]);
    },
  });
};

// sve email workflow
const saveEmailWorkflow = async (values: SaveEmailWorkflow) => {
  const { workflowId, data } = values;
  return axiosInstance.post<SaveEmailWorkflowResponse>(
    `${routes['workflows']}/${workflowId}/contents/email`,
    data,
  );
};

export const useSaveEmailWorkflow = () => {
  const { appId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: saveEmailWorkflow,
    onSuccess: () => {
      queryClient.invalidateQueries([
        `${routes['apps']}/workflows-email`,
        appId,
      ]);
    },
  });
};
